@import url(https://fonts.googleapis.com/css?family=Lato:300,400,400i,700,900);
@charset "UTF-8";

/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #328fe2;
  --indigo: #6610f2;
  --purple: #933ddf;
  --pink: #e83e8c;
  --red: #f54156;
  --orange: #f47b30;
  --yellow: #ffbf00;
  --green: #32b734;
  --teal: #3dbfbe;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #8a8a8a;
  --gray-dark: #5e5e5e;
  --primary: #3dbfbe;
  --secondary: #f47b30;
  --success: #32b734;
  --info: #328fe2;
  --warning: #ffbf00;
  --danger: #f54156;
  --light: #f3f3f3;
  --dark: #5e5e5e;
  --white: #fff;
  --black: #000;
  --purple: #933ddf;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1025px;
  --breakpoint-xl: 1441px;
  --breakpoint-xxl: 1921px;
  --font-family-sans-serif: "Lato", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  color: #484848;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1.5rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #3dbfbe;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #2a8584;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #cccccc;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .card-title, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2rem; }

h2, .h2 {
  font-size: 1.5rem; }

h3, .h3, .card-title {
  font-size: 1.0625rem; }

h4, .h4 {
  font-size: 1rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #8a8a8a; }
  .blockquote-footer::before {
    content: "\2014\A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #cccccc;
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #8a8a8a; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #484848;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #484848; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 1025px) {
    .container {
      max-width: 990px; } }
  @media (min-width: 1441px) {
    .container {
      max-width: 1400px; } }
  @media (min-width: 1921px) {
    .container {
      max-width: 1880px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row, .ais-InstantSearch__root .ais-Hits-list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .Search .ais-Hits-item, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12, .Search .ais-Hits-item {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6, .Search .ais-Hits-item {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4, .Search .ais-Hits-item {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1025px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3, .Search .ais-Hits-item {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1441px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2, .Search .ais-Hits-item {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1921px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #484848; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #f3f3f3; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #f3f3f3; }
  .table tbody + tbody {
    border-top: 2px solid #f3f3f3; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #f3f3f3; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #f3f3f3; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #484848;
  background-color: #3dbfbe; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c9eded; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #9adedd; }

.table-hover .table-primary:hover {
  background-color: #b6e7e7; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #b6e7e7; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #fcdac5; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #f9ba93; }

.table-hover .table-secondary:hover {
  background-color: #fbcbad; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #fbcbad; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c6ebc6; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #94da95; }

.table-hover .table-success:hover {
  background-color: #b3e4b3; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b3e4b3; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c6e0f7; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #94c5f0; }

.table-hover .table-info:hover {
  background-color: #b0d4f4; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #b0d4f4; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffedb8; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffde7a; }

.table-hover .table-warning:hover {
  background-color: #ffe79f; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe79f; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fccad0; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #fa9ca7; }

.table-hover .table-danger:hover {
  background-color: #fbb2bb; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #fbb2bb; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fcfcfc; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f9f9f9; }

.table-hover .table-light:hover {
  background-color: #efefef; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #efefef; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #d2d2d2; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #ababab; }

.table-hover .table-dark:hover {
  background-color: #c5c5c5; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #c5c5c5; }

.table-white,
.table-white > th,
.table-white > td {
  background-color: white; }

.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody {
  border-color: white; }

.table-hover .table-white:hover {
  background-color: #f2f2f2; }
  .table-hover .table-white:hover > td,
  .table-hover .table-white:hover > th {
    background-color: #f2f2f2; }

.table-black,
.table-black > th,
.table-black > td {
  background-color: #b8b8b8; }

.table-black th,
.table-black td,
.table-black thead th,
.table-black tbody + tbody {
  border-color: #7a7a7a; }

.table-hover .table-black:hover {
  background-color: #ababab; }
  .table-hover .table-black:hover > td,
  .table-hover .table-black:hover > th {
    background-color: #ababab; }

.table-purple,
.table-purple > th,
.table-purple > td {
  background-color: #e1c9f6; }

.table-purple th,
.table-purple td,
.table-purple thead th,
.table-purple tbody + tbody {
  border-color: #c79aee; }

.table-hover .table-purple:hover {
  background-color: #d5b3f2; }
  .table-hover .table-purple:hover > td,
  .table-hover .table-purple:hover > th {
    background-color: #d5b3f2; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: #3dbfbe; }

.table-hover .table-active:hover {
  background-color: #37acab; }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: #37acab; }

.table .thead-dark th {
  color: #fff;
  background-color: #5e5e5e;
  border-color: #717171; }

.table .thead-light th {
  color: #747474;
  background-color: #f3f3f3;
  border-color: #f3f3f3; }

.table-dark {
  color: #fff;
  background-color: #5e5e5e; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #717171; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 1024.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1440.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

@media (max-width: 1920.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xxl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control, .ais-InstantSearch__root .ais-SortBy-select, .AutoCompleteSearch .react-autosuggest__input, .Checkout .StripeElement {
  display: block;
  width: 100%;
  height: calc(1.4em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  color: #747474;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cccccc;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control, .ais-InstantSearch__root .ais-SortBy-select, .AutoCompleteSearch .react-autosuggest__input, .Checkout .StripeElement {
      transition: none; } }
  .form-control::-ms-expand, .ais-InstantSearch__root .ais-SortBy-select::-ms-expand, .AutoCompleteSearch .react-autosuggest__input::-ms-expand, .Checkout .StripeElement::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus, .ais-InstantSearch__root .ais-SortBy-select:focus, .AutoCompleteSearch .react-autosuggest__input:focus, .Checkout .StripeElement:focus {
    color: #747474;
    background-color: #fff;
    border-color: #9cdfdf;
    outline: 0;
    box-shadow: 0 0 0 0.3rem rgba(61, 191, 190, 0.25); }
  .form-control::-webkit-input-placeholder, .ais-InstantSearch__root .ais-SortBy-select::-webkit-input-placeholder, .AutoCompleteSearch .react-autosuggest__input::-webkit-input-placeholder, .Checkout .StripeElement::-webkit-input-placeholder {
    color: #b6b6b6;
    opacity: 1; }
  .form-control:-ms-input-placeholder, .ais-InstantSearch__root .ais-SortBy-select:-ms-input-placeholder, .AutoCompleteSearch .react-autosuggest__input:-ms-input-placeholder, .Checkout .StripeElement:-ms-input-placeholder {
    color: #b6b6b6;
    opacity: 1; }
  .form-control::-ms-input-placeholder, .ais-InstantSearch__root .ais-SortBy-select::-ms-input-placeholder, .AutoCompleteSearch .react-autosuggest__input::-ms-input-placeholder, .Checkout .StripeElement::-ms-input-placeholder {
    color: #b6b6b6;
    opacity: 1; }
  .form-control::placeholder, .ais-InstantSearch__root .ais-SortBy-select::placeholder, .AutoCompleteSearch .react-autosuggest__input::placeholder, .Checkout .StripeElement::placeholder {
    color: #b6b6b6;
    opacity: 1; }
  .form-control:disabled, .ais-InstantSearch__root .ais-SortBy-select:disabled, .AutoCompleteSearch .react-autosuggest__input:disabled, .Checkout .StripeElement:disabled, .form-control[readonly], .ais-InstantSearch__root .ais-SortBy-select[readonly], .AutoCompleteSearch .react-autosuggest__input[readonly], .Checkout .StripeElement[readonly] {
    background-color: #f3f3f3;
    opacity: 1; }

select.form-control:focus::-ms-value, .ais-InstantSearch__root select.ais-SortBy-select:focus::-ms-value, .AutoCompleteSearch select.react-autosuggest__input:focus::-ms-value, .Checkout select.StripeElement:focus::-ms-value {
  color: #747474;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.4; }

.col-form-label-lg {
  padding-top: calc(0.65rem + 1px);
  padding-bottom: calc(0.65rem + 1px);
  font-size: 1.1rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.4;
  color: #484848;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg, .Checkout .form-control-plaintext.StripeElement {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg, .Checkout .StripeElement {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1.1rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], .ais-InstantSearch__root select.ais-SortBy-select[size], .AutoCompleteSearch select.react-autosuggest__input[size], .Checkout select.StripeElement[size], select.form-control[multiple], .ais-InstantSearch__root select.ais-SortBy-select[multiple], .AutoCompleteSearch select.react-autosuggest__input[multiple], .Checkout select.StripeElement[multiple] {
  height: auto; }

textarea.form-control, .ais-InstantSearch__root textarea.ais-SortBy-select, .AutoCompleteSearch textarea.react-autosuggest__input, .Checkout textarea.StripeElement {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #cccccc; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #32b734; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.4;
  color: #fff;
  background-color: rgba(50, 183, 52, 0.9);
  border-radius: 0.375rem; }

.was-validated .form-control:valid, .was-validated .ais-InstantSearch__root .ais-SortBy-select:valid, .ais-InstantSearch__root .was-validated .ais-SortBy-select:valid, .was-validated .AutoCompleteSearch .react-autosuggest__input:valid, .AutoCompleteSearch .was-validated .react-autosuggest__input:valid, .was-validated .Checkout .StripeElement:valid, .Checkout .was-validated .StripeElement:valid, .form-control.is-valid, .ais-InstantSearch__root .is-valid.ais-SortBy-select, .AutoCompleteSearch .is-valid.react-autosuggest__input, .Checkout .is-valid.StripeElement {
  border-color: #32b734;
  padding-right: calc(1.4em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2332b734' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.35em + 0.1875rem);
  background-size: calc(0.7em + 0.375rem) calc(0.7em + 0.375rem); }
  .was-validated .form-control:valid:focus, .was-validated .ais-InstantSearch__root .ais-SortBy-select:valid:focus, .ais-InstantSearch__root .was-validated .ais-SortBy-select:valid:focus, .was-validated .AutoCompleteSearch .react-autosuggest__input:valid:focus, .AutoCompleteSearch .was-validated .react-autosuggest__input:valid:focus, .was-validated .Checkout .StripeElement:valid:focus, .Checkout .was-validated .StripeElement:valid:focus, .form-control.is-valid:focus, .ais-InstantSearch__root .is-valid.ais-SortBy-select:focus, .AutoCompleteSearch .is-valid.react-autosuggest__input:focus, .Checkout .is-valid.StripeElement:focus {
    border-color: #32b734;
    box-shadow: 0 0 0 0.3rem rgba(50, 183, 52, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback, .was-validated .ais-InstantSearch__root .ais-SortBy-select:valid ~ .valid-feedback, .ais-InstantSearch__root .was-validated .ais-SortBy-select:valid ~ .valid-feedback, .was-validated .AutoCompleteSearch .react-autosuggest__input:valid ~ .valid-feedback, .AutoCompleteSearch .was-validated .react-autosuggest__input:valid ~ .valid-feedback, .was-validated .Checkout .StripeElement:valid ~ .valid-feedback, .Checkout .was-validated .StripeElement:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip,
  .was-validated .ais-InstantSearch__root .ais-SortBy-select:valid ~ .valid-tooltip,
  .ais-InstantSearch__root .was-validated .ais-SortBy-select:valid ~ .valid-tooltip,
  .was-validated .AutoCompleteSearch .react-autosuggest__input:valid ~ .valid-tooltip,
  .AutoCompleteSearch .was-validated .react-autosuggest__input:valid ~ .valid-tooltip,
  .was-validated .Checkout .StripeElement:valid ~ .valid-tooltip,
  .Checkout .was-validated .StripeElement:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, .ais-InstantSearch__root .is-valid.ais-SortBy-select ~ .valid-feedback, .AutoCompleteSearch .is-valid.react-autosuggest__input ~ .valid-feedback, .Checkout .is-valid.StripeElement ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip,
  .ais-InstantSearch__root .is-valid.ais-SortBy-select ~ .valid-tooltip,
  .AutoCompleteSearch .is-valid.react-autosuggest__input ~ .valid-tooltip,
  .Checkout .is-valid.StripeElement ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, .was-validated .ais-InstantSearch__root textarea.ais-SortBy-select:valid, .ais-InstantSearch__root .was-validated textarea.ais-SortBy-select:valid, .was-validated .AutoCompleteSearch textarea.react-autosuggest__input:valid, .AutoCompleteSearch .was-validated textarea.react-autosuggest__input:valid, .was-validated .Checkout textarea.StripeElement:valid, .Checkout .was-validated textarea.StripeElement:valid, textarea.form-control.is-valid, .ais-InstantSearch__root textarea.is-valid.ais-SortBy-select, .AutoCompleteSearch textarea.is-valid.react-autosuggest__input, .Checkout textarea.is-valid.StripeElement {
  padding-right: calc(1.4em + 0.75rem);
  background-position: top calc(0.35em + 0.1875rem) right calc(0.35em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #32b734;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%235e5e5e' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2332b734' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.7em + 0.375rem) calc(0.7em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #32b734;
    box-shadow: 0 0 0 0.3rem rgba(50, 183, 52, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #32b734; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #32b734; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #32b734; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #4dcf4f;
  background: #4dcf4f linear-gradient(180deg, #68d66a, #4dcf4f) repeat-x; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.3rem rgba(50, 183, 52, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #32b734; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #32b734; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #32b734;
  box-shadow: 0 0 0 0.3rem rgba(50, 183, 52, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f54156; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.4;
  color: #fff;
  background-color: rgba(245, 65, 86, 0.9);
  border-radius: 0.375rem; }

.was-validated .form-control:invalid, .was-validated .ais-InstantSearch__root .ais-SortBy-select:invalid, .ais-InstantSearch__root .was-validated .ais-SortBy-select:invalid, .was-validated .AutoCompleteSearch .react-autosuggest__input:invalid, .AutoCompleteSearch .was-validated .react-autosuggest__input:invalid, .was-validated .Checkout .StripeElement:invalid, .Checkout .was-validated .StripeElement:invalid, .form-control.is-invalid, .ais-InstantSearch__root .is-invalid.ais-SortBy-select, .AutoCompleteSearch .is-invalid.react-autosuggest__input, .Checkout .is-invalid.StripeElement {
  border-color: #f54156;
  padding-right: calc(1.4em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f54156' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f54156' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.35em + 0.1875rem);
  background-size: calc(0.7em + 0.375rem) calc(0.7em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .was-validated .ais-InstantSearch__root .ais-SortBy-select:invalid:focus, .ais-InstantSearch__root .was-validated .ais-SortBy-select:invalid:focus, .was-validated .AutoCompleteSearch .react-autosuggest__input:invalid:focus, .AutoCompleteSearch .was-validated .react-autosuggest__input:invalid:focus, .was-validated .Checkout .StripeElement:invalid:focus, .Checkout .was-validated .StripeElement:invalid:focus, .form-control.is-invalid:focus, .ais-InstantSearch__root .is-invalid.ais-SortBy-select:focus, .AutoCompleteSearch .is-invalid.react-autosuggest__input:focus, .Checkout .is-invalid.StripeElement:focus {
    border-color: #f54156;
    box-shadow: 0 0 0 0.3rem rgba(245, 65, 86, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .ais-InstantSearch__root .ais-SortBy-select:invalid ~ .invalid-feedback, .ais-InstantSearch__root .was-validated .ais-SortBy-select:invalid ~ .invalid-feedback, .was-validated .AutoCompleteSearch .react-autosuggest__input:invalid ~ .invalid-feedback, .AutoCompleteSearch .was-validated .react-autosuggest__input:invalid ~ .invalid-feedback, .was-validated .Checkout .StripeElement:invalid ~ .invalid-feedback, .Checkout .was-validated .StripeElement:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip,
  .was-validated .ais-InstantSearch__root .ais-SortBy-select:invalid ~ .invalid-tooltip,
  .ais-InstantSearch__root .was-validated .ais-SortBy-select:invalid ~ .invalid-tooltip,
  .was-validated .AutoCompleteSearch .react-autosuggest__input:invalid ~ .invalid-tooltip,
  .AutoCompleteSearch .was-validated .react-autosuggest__input:invalid ~ .invalid-tooltip,
  .was-validated .Checkout .StripeElement:invalid ~ .invalid-tooltip,
  .Checkout .was-validated .StripeElement:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .ais-InstantSearch__root .is-invalid.ais-SortBy-select ~ .invalid-feedback, .AutoCompleteSearch .is-invalid.react-autosuggest__input ~ .invalid-feedback, .Checkout .is-invalid.StripeElement ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip,
  .ais-InstantSearch__root .is-invalid.ais-SortBy-select ~ .invalid-tooltip,
  .AutoCompleteSearch .is-invalid.react-autosuggest__input ~ .invalid-tooltip,
  .Checkout .is-invalid.StripeElement ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, .was-validated .ais-InstantSearch__root textarea.ais-SortBy-select:invalid, .ais-InstantSearch__root .was-validated textarea.ais-SortBy-select:invalid, .was-validated .AutoCompleteSearch textarea.react-autosuggest__input:invalid, .AutoCompleteSearch .was-validated textarea.react-autosuggest__input:invalid, .was-validated .Checkout textarea.StripeElement:invalid, .Checkout .was-validated textarea.StripeElement:invalid, textarea.form-control.is-invalid, .ais-InstantSearch__root textarea.is-invalid.ais-SortBy-select, .AutoCompleteSearch textarea.is-invalid.react-autosuggest__input, .Checkout textarea.is-invalid.StripeElement {
  padding-right: calc(1.4em + 0.75rem);
  background-position: top calc(0.35em + 0.1875rem) right calc(0.35em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #f54156;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%235e5e5e' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f54156' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f54156' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.7em + 0.375rem) calc(0.7em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #f54156;
    box-shadow: 0 0 0 0.3rem rgba(245, 65, 86, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #f54156; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #f54156; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #f54156; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f87181;
  background: #f87181 linear-gradient(180deg, #f98794, #f87181) repeat-x; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.3rem rgba(245, 65, 86, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #f54156; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #f54156; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #f54156;
  box-shadow: 0 0 0 0.3rem rgba(245, 65, 86, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control, .form-inline .ais-InstantSearch__root .ais-SortBy-select, .ais-InstantSearch__root .form-inline .ais-SortBy-select, .form-inline .AutoCompleteSearch .react-autosuggest__input, .AutoCompleteSearch .form-inline .react-autosuggest__input, .form-inline .Checkout .StripeElement, .Checkout .form-inline .StripeElement {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn, .cookieConsent button {
  display: inline-block;
  font-weight: 400;
  color: #484848;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.25rem 0.75rem;
  font-size: 1rem;
  line-height: 1.4;
  border-radius: 0.375rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn, .cookieConsent button {
      transition: none; } }
  .btn:hover, .cookieConsent button:hover {
    color: #484848;
    text-decoration: none; }
  .btn:focus, .cookieConsent button:focus, .btn.focus, .cookieConsent button.focus {
    outline: 0;
    box-shadow: 0 0 0 0.3rem rgba(61, 191, 190, 0.25); }
  .btn.disabled, .cookieConsent button.disabled, .btn:disabled, .cookieConsent button:disabled {
    opacity: 0.5; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #484848;
  background: #3dbfbe linear-gradient(180deg, #5ac9c8, #3dbfbe) repeat-x;
  border-color: #3dbfbe; }
  .btn-primary:hover {
    color: #fff;
    background: #34a2a1 linear-gradient(180deg, #52b0af, #34a2a1) repeat-x;
    border-color: #319898; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.3rem rgba(63, 173, 172, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #484848;
    background-color: #3dbfbe;
    border-color: #3dbfbe;
    background-image: none; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .ais-InstantSearch__root .btn-primary.ais-Pagination-item--selected:not(:disabled):not(.disabled),
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #319898;
    background-image: none;
    border-color: #2e8f8e; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .ais-InstantSearch__root .btn-primary.ais-Pagination-item--selected:not(:disabled):not(.disabled):focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.3rem rgba(63, 173, 172, 0.5); }

.btn-secondary {
  color: #484848;
  background: #f47b30 linear-gradient(180deg, #f68f4f, #f47b30) repeat-x;
  border-color: #f47b30; }
  .btn-secondary:hover {
    color: #fff;
    background: #f1640d linear-gradient(180deg, #f37b31, #f1640d) repeat-x;
    border-color: #e55f0c; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.3rem rgba(218, 115, 52, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #484848;
    background-color: #f47b30;
    border-color: #f47b30;
    background-image: none; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .ais-InstantSearch__root .btn-secondary.ais-Pagination-item--selected:not(:disabled):not(.disabled),
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #e55f0c;
    background-image: none;
    border-color: #d95a0c; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .ais-InstantSearch__root .btn-secondary.ais-Pagination-item--selected:not(:disabled):not(.disabled):focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.3rem rgba(218, 115, 52, 0.5); }

.btn-success {
  color: #fff;
  background: #32b734 linear-gradient(180deg, #51c252, #32b734) repeat-x;
  border-color: #32b734; }
  .btn-success:hover {
    color: #fff;
    background: #2a992b linear-gradient(180deg, #4aa84b, #2a992b) repeat-x;
    border-color: #278f29; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.3rem rgba(81, 194, 82, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #32b734;
    border-color: #32b734;
    background-image: none; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .ais-InstantSearch__root .btn-success.ais-Pagination-item--selected:not(:disabled):not(.disabled),
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #278f29;
    background-image: none;
    border-color: #248526; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .ais-InstantSearch__root .btn-success.ais-Pagination-item--selected:not(:disabled):not(.disabled):focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.3rem rgba(81, 194, 82, 0.5); }

.btn-info {
  color: #fff;
  background: #328fe2 linear-gradient(180deg, #51a0e6, #328fe2) repeat-x;
  border-color: #328fe2; }
  .btn-info:hover {
    color: #fff;
    background: #1d7cd0 linear-gradient(180deg, #3f90d7, #1d7cd0) repeat-x;
    border-color: #1c75c5; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.3rem rgba(81, 160, 230, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #328fe2;
    border-color: #328fe2;
    background-image: none; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .ais-InstantSearch__root .btn-info.ais-Pagination-item--selected:not(:disabled):not(.disabled),
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #1c75c5;
    background-image: none;
    border-color: #1a6fba; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .ais-InstantSearch__root .btn-info.ais-Pagination-item--selected:not(:disabled):not(.disabled):focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.3rem rgba(81, 160, 230, 0.5); }

.btn-warning, .cookieConsent button {
  color: #484848;
  background: #ffbf00 linear-gradient(180deg, #ffc926, #ffbf00) repeat-x;
  border-color: #ffbf00; }
  .btn-warning:hover, .cookieConsent button:hover {
    color: #484848;
    background: #d9a200 linear-gradient(180deg, #deb026, #d9a200) repeat-x;
    border-color: #cc9900; }
  .btn-warning:focus, .cookieConsent button:focus, .btn-warning.focus, .cookieConsent button.focus {
    box-shadow: 0 0 0 0.3rem rgba(228, 173, 11, 0.5); }
  .btn-warning.disabled, .cookieConsent button.disabled, .btn-warning:disabled, .cookieConsent button:disabled {
    color: #484848;
    background-color: #ffbf00;
    border-color: #ffbf00;
    background-image: none; }
  .btn-warning:not(:disabled):not(.disabled):active, .cookieConsent button:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .cookieConsent button:not(:disabled):not(.disabled).active, .cookieConsent .ais-InstantSearch__root button.ais-Pagination-item--selected:not(:disabled):not(.disabled), .ais-InstantSearch__root .cookieConsent button.ais-Pagination-item--selected:not(:disabled):not(.disabled), .ais-InstantSearch__root .btn-warning.ais-Pagination-item--selected:not(:disabled):not(.disabled),
  .show > .btn-warning.dropdown-toggle,
  .cookieConsent .show > button.dropdown-toggle {
    color: #484848;
    background-color: #cc9900;
    background-image: none;
    border-color: #bf8f00; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .cookieConsent button:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .cookieConsent button:not(:disabled):not(.disabled).active:focus, .cookieConsent .ais-InstantSearch__root button.ais-Pagination-item--selected:not(:disabled):not(.disabled):focus, .ais-InstantSearch__root .cookieConsent button.ais-Pagination-item--selected:not(:disabled):not(.disabled):focus, .ais-InstantSearch__root .btn-warning.ais-Pagination-item--selected:not(:disabled):not(.disabled):focus,
    .show > .btn-warning.dropdown-toggle:focus,
    .cookieConsent .show > button.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.3rem rgba(228, 173, 11, 0.5); }

.btn-danger {
  color: #fff;
  background: #f54156 linear-gradient(180deg, #f75e6f, #f54156) repeat-x;
  border-color: #f54156; }
  .btn-danger:hover {
    color: #fff;
    background: #f31d36 linear-gradient(180deg, #f53f54, #f31d36) repeat-x;
    border-color: #f2112b; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.3rem rgba(247, 94, 111, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #f54156;
    border-color: #f54156;
    background-image: none; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .ais-InstantSearch__root .btn-danger.ais-Pagination-item--selected:not(:disabled):not(.disabled),
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #f2112b;
    background-image: none;
    border-color: #ea0c26; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .ais-InstantSearch__root .btn-danger.ais-Pagination-item--selected:not(:disabled):not(.disabled):focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.3rem rgba(247, 94, 111, 0.5); }

.btn-light {
  color: #484848;
  background: #f3f3f3 linear-gradient(180deg, whitesmoke, #f3f3f3) repeat-x;
  border-color: #f3f3f3; }
  .btn-light:hover {
    color: #484848;
    background: #e0e0e0 linear-gradient(180deg, #e5e5e5, #e0e0e0) repeat-x;
    border-color: #dadada; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.3rem rgba(217, 217, 217, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #484848;
    background-color: #f3f3f3;
    border-color: #f3f3f3;
    background-image: none; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .ais-InstantSearch__root .btn-light.ais-Pagination-item--selected:not(:disabled):not(.disabled),
  .show > .btn-light.dropdown-toggle {
    color: #484848;
    background-color: #dadada;
    background-image: none;
    border-color: lightgray; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .ais-InstantSearch__root .btn-light.ais-Pagination-item--selected:not(:disabled):not(.disabled):focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.3rem rgba(217, 217, 217, 0.5); }

.btn-dark {
  color: #fff;
  background: #5e5e5e linear-gradient(180deg, #767676, #5e5e5e) repeat-x;
  border-color: #5e5e5e; }
  .btn-dark:hover {
    color: #fff;
    background: #4b4b4b linear-gradient(180deg, #666666, #4b4b4b) repeat-x;
    border-color: #454545; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.3rem rgba(118, 118, 118, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #5e5e5e;
    border-color: #5e5e5e;
    background-image: none; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .ais-InstantSearch__root .btn-dark.ais-Pagination-item--selected:not(:disabled):not(.disabled),
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #454545;
    background-image: none;
    border-color: #3e3e3e; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .ais-InstantSearch__root .btn-dark.ais-Pagination-item--selected:not(:disabled):not(.disabled):focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.3rem rgba(118, 118, 118, 0.5); }

.btn-white {
  color: #484848;
  background: #fff linear-gradient(180deg, white, #fff) repeat-x;
  border-color: #fff; }
  .btn-white:hover {
    color: #484848;
    background: #ececec linear-gradient(180deg, #efefef, #ececec) repeat-x;
    border-color: #e6e6e6; }
  .btn-white:focus, .btn-white.focus {
    box-shadow: 0 0 0 0.3rem rgba(228, 228, 228, 0.5); }
  .btn-white.disabled, .btn-white:disabled {
    color: #484848;
    background-color: #fff;
    border-color: #fff;
    background-image: none; }
  .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active, .ais-InstantSearch__root .btn-white.ais-Pagination-item--selected:not(:disabled):not(.disabled),
  .show > .btn-white.dropdown-toggle {
    color: #484848;
    background-color: #e6e6e6;
    background-image: none;
    border-color: #dfdfdf; }
    .btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus, .ais-InstantSearch__root .btn-white.ais-Pagination-item--selected:not(:disabled):not(.disabled):focus,
    .show > .btn-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.3rem rgba(228, 228, 228, 0.5); }

.btn-black {
  color: #fff;
  background: #000 linear-gradient(180deg, #262626, #000) repeat-x;
  border-color: #000; }
  .btn-black:hover {
    color: #fff;
    background: black linear-gradient(180deg, #262626, black) repeat-x;
    border-color: black; }
  .btn-black:focus, .btn-black.focus {
    box-shadow: 0 0 0 0.3rem rgba(38, 38, 38, 0.5); }
  .btn-black.disabled, .btn-black:disabled {
    color: #fff;
    background-color: #000;
    border-color: #000;
    background-image: none; }
  .btn-black:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active, .ais-InstantSearch__root .btn-black.ais-Pagination-item--selected:not(:disabled):not(.disabled),
  .show > .btn-black.dropdown-toggle {
    color: #fff;
    background-color: black;
    background-image: none;
    border-color: black; }
    .btn-black:not(:disabled):not(.disabled):active:focus, .btn-black:not(:disabled):not(.disabled).active:focus, .ais-InstantSearch__root .btn-black.ais-Pagination-item--selected:not(:disabled):not(.disabled):focus,
    .show > .btn-black.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.3rem rgba(38, 38, 38, 0.5); }

.btn-purple {
  color: #fff;
  background: #933ddf linear-gradient(180deg, #a35ae4, #933ddf) repeat-x;
  border-color: #933ddf; }
  .btn-purple:hover {
    color: #fff;
    background: #8023d3 linear-gradient(180deg, #9344da, #8023d3) repeat-x;
    border-color: #7a21c8; }
  .btn-purple:focus, .btn-purple.focus {
    box-shadow: 0 0 0 0.3rem rgba(163, 90, 228, 0.5); }
  .btn-purple.disabled, .btn-purple:disabled {
    color: #fff;
    background-color: #933ddf;
    border-color: #933ddf;
    background-image: none; }
  .btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active, .ais-InstantSearch__root .btn-purple.ais-Pagination-item--selected:not(:disabled):not(.disabled),
  .show > .btn-purple.dropdown-toggle {
    color: #fff;
    background-color: #7a21c8;
    background-image: none;
    border-color: #731fbd; }
    .btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus, .ais-InstantSearch__root .btn-purple.ais-Pagination-item--selected:not(:disabled):not(.disabled):focus,
    .show > .btn-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.3rem rgba(163, 90, 228, 0.5); }

.btn-outline-primary {
  color: #3dbfbe;
  border-color: #3dbfbe; }
  .btn-outline-primary:hover {
    color: #484848;
    background-color: #3dbfbe;
    border-color: #3dbfbe; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.3rem rgba(61, 191, 190, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #3dbfbe;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .ais-InstantSearch__root .btn-outline-primary.ais-Pagination-item--selected:not(:disabled):not(.disabled),
  .show > .btn-outline-primary.dropdown-toggle {
    color: #484848;
    background-color: #3dbfbe;
    border-color: #3dbfbe; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .ais-InstantSearch__root .btn-outline-primary.ais-Pagination-item--selected:not(:disabled):not(.disabled):focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.3rem rgba(61, 191, 190, 0.5); }

.btn-outline-secondary {
  color: #f47b30;
  border-color: #f47b30; }
  .btn-outline-secondary:hover {
    color: #484848;
    background-color: #f47b30;
    border-color: #f47b30; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.3rem rgba(244, 123, 48, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #f47b30;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .ais-InstantSearch__root .btn-outline-secondary.ais-Pagination-item--selected:not(:disabled):not(.disabled),
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #484848;
    background-color: #f47b30;
    border-color: #f47b30; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .ais-InstantSearch__root .btn-outline-secondary.ais-Pagination-item--selected:not(:disabled):not(.disabled):focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.3rem rgba(244, 123, 48, 0.5); }

.btn-outline-success {
  color: #32b734;
  border-color: #32b734; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #32b734;
    border-color: #32b734; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.3rem rgba(50, 183, 52, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #32b734;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .ais-InstantSearch__root .btn-outline-success.ais-Pagination-item--selected:not(:disabled):not(.disabled),
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #32b734;
    border-color: #32b734; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .ais-InstantSearch__root .btn-outline-success.ais-Pagination-item--selected:not(:disabled):not(.disabled):focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.3rem rgba(50, 183, 52, 0.5); }

.btn-outline-info {
  color: #328fe2;
  border-color: #328fe2; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #328fe2;
    border-color: #328fe2; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.3rem rgba(50, 143, 226, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #328fe2;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .ais-InstantSearch__root .btn-outline-info.ais-Pagination-item--selected:not(:disabled):not(.disabled),
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #328fe2;
    border-color: #328fe2; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .ais-InstantSearch__root .btn-outline-info.ais-Pagination-item--selected:not(:disabled):not(.disabled):focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.3rem rgba(50, 143, 226, 0.5); }

.btn-outline-warning {
  color: #ffbf00;
  border-color: #ffbf00; }
  .btn-outline-warning:hover {
    color: #484848;
    background-color: #ffbf00;
    border-color: #ffbf00; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.3rem rgba(255, 191, 0, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffbf00;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .ais-InstantSearch__root .btn-outline-warning.ais-Pagination-item--selected:not(:disabled):not(.disabled),
  .show > .btn-outline-warning.dropdown-toggle {
    color: #484848;
    background-color: #ffbf00;
    border-color: #ffbf00; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .ais-InstantSearch__root .btn-outline-warning.ais-Pagination-item--selected:not(:disabled):not(.disabled):focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.3rem rgba(255, 191, 0, 0.5); }

.btn-outline-danger {
  color: #f54156;
  border-color: #f54156; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #f54156;
    border-color: #f54156; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.3rem rgba(245, 65, 86, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #f54156;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .ais-InstantSearch__root .btn-outline-danger.ais-Pagination-item--selected:not(:disabled):not(.disabled),
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f54156;
    border-color: #f54156; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .ais-InstantSearch__root .btn-outline-danger.ais-Pagination-item--selected:not(:disabled):not(.disabled):focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.3rem rgba(245, 65, 86, 0.5); }

.btn-outline-light {
  color: #f3f3f3;
  border-color: #f3f3f3; }
  .btn-outline-light:hover {
    color: #484848;
    background-color: #f3f3f3;
    border-color: #f3f3f3; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.3rem rgba(243, 243, 243, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f3f3f3;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .ais-InstantSearch__root .btn-outline-light.ais-Pagination-item--selected:not(:disabled):not(.disabled),
  .show > .btn-outline-light.dropdown-toggle {
    color: #484848;
    background-color: #f3f3f3;
    border-color: #f3f3f3; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .ais-InstantSearch__root .btn-outline-light.ais-Pagination-item--selected:not(:disabled):not(.disabled):focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.3rem rgba(243, 243, 243, 0.5); }

.btn-outline-dark {
  color: #5e5e5e;
  border-color: #5e5e5e; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #5e5e5e;
    border-color: #5e5e5e; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.3rem rgba(94, 94, 94, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #5e5e5e;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .ais-InstantSearch__root .btn-outline-dark.ais-Pagination-item--selected:not(:disabled):not(.disabled),
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #5e5e5e;
    border-color: #5e5e5e; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .ais-InstantSearch__root .btn-outline-dark.ais-Pagination-item--selected:not(:disabled):not(.disabled):focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.3rem rgba(94, 94, 94, 0.5); }

.btn-outline-white {
  color: #fff;
  border-color: #fff; }
  .btn-outline-white:hover {
    color: #484848;
    background-color: #fff;
    border-color: #fff; }
  .btn-outline-white:focus, .btn-outline-white.focus {
    box-shadow: 0 0 0 0.3rem rgba(255, 255, 255, 0.5); }
  .btn-outline-white.disabled, .btn-outline-white:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active, .ais-InstantSearch__root .btn-outline-white.ais-Pagination-item--selected:not(:disabled):not(.disabled),
  .show > .btn-outline-white.dropdown-toggle {
    color: #484848;
    background-color: #fff;
    border-color: #fff; }
    .btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus, .ais-InstantSearch__root .btn-outline-white.ais-Pagination-item--selected:not(:disabled):not(.disabled):focus,
    .show > .btn-outline-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.3rem rgba(255, 255, 255, 0.5); }

.btn-outline-black {
  color: #000;
  border-color: #000; }
  .btn-outline-black:hover {
    color: #fff;
    background-color: #000;
    border-color: #000; }
  .btn-outline-black:focus, .btn-outline-black.focus {
    box-shadow: 0 0 0 0.3rem rgba(0, 0, 0, 0.5); }
  .btn-outline-black.disabled, .btn-outline-black:disabled {
    color: #000;
    background-color: transparent; }
  .btn-outline-black:not(:disabled):not(.disabled):active, .btn-outline-black:not(:disabled):not(.disabled).active, .ais-InstantSearch__root .btn-outline-black.ais-Pagination-item--selected:not(:disabled):not(.disabled),
  .show > .btn-outline-black.dropdown-toggle {
    color: #fff;
    background-color: #000;
    border-color: #000; }
    .btn-outline-black:not(:disabled):not(.disabled):active:focus, .btn-outline-black:not(:disabled):not(.disabled).active:focus, .ais-InstantSearch__root .btn-outline-black.ais-Pagination-item--selected:not(:disabled):not(.disabled):focus,
    .show > .btn-outline-black.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.3rem rgba(0, 0, 0, 0.5); }

.btn-outline-purple {
  color: #933ddf;
  border-color: #933ddf; }
  .btn-outline-purple:hover {
    color: #fff;
    background-color: #933ddf;
    border-color: #933ddf; }
  .btn-outline-purple:focus, .btn-outline-purple.focus {
    box-shadow: 0 0 0 0.3rem rgba(147, 61, 223, 0.5); }
  .btn-outline-purple.disabled, .btn-outline-purple:disabled {
    color: #933ddf;
    background-color: transparent; }
  .btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active, .ais-InstantSearch__root .btn-outline-purple.ais-Pagination-item--selected:not(:disabled):not(.disabled),
  .show > .btn-outline-purple.dropdown-toggle {
    color: #fff;
    background-color: #933ddf;
    border-color: #933ddf; }
    .btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active:focus, .ais-InstantSearch__root .btn-outline-purple.ais-Pagination-item--selected:not(:disabled):not(.disabled):focus,
    .show > .btn-outline-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.3rem rgba(147, 61, 223, 0.5); }

.btn-link {
  font-weight: 400;
  color: #3dbfbe;
  text-decoration: none; }
  .btn-link:hover {
    color: #2a8584;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #8a8a8a;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn, .cookieConsent .btn-group-lg > button {
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  line-height: 1.65;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn, .cookieConsent .btn-group-sm > button {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #484848;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.375rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 1025px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1441px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1921px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #f3f3f3; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #484848;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #3b3b3b;
    text-decoration: none;
    background: #f8f9fa linear-gradient(180deg, #f9fafb, #f8f9fa) repeat-x; }
  .dropdown-item.active, .ais-InstantSearch__root .dropdown-item.ais-Pagination-item--selected, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background: #3dbfbe linear-gradient(180deg, #5ac9c8, #3dbfbe) repeat-x; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #8a8a8a;
    pointer-events: none;
    background-color: transparent;
    background-image: none; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #8a8a8a;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #484848; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn, .cookieConsent .btn-group > button,
  .btn-group-vertical > .btn,
  .cookieConsent .btn-group-vertical > button {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover, .cookieConsent .btn-group > button:hover,
    .btn-group-vertical > .btn:hover,
    .cookieConsent .btn-group-vertical > button:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .cookieConsent .btn-group > button:focus, .btn-group > .btn:active, .cookieConsent .btn-group > button:active, .btn-group > .btn.active, .cookieConsent .btn-group > button.active, .cookieConsent .ais-InstantSearch__root .btn-group > button.ais-Pagination-item--selected, .ais-InstantSearch__root .cookieConsent .btn-group > button.ais-Pagination-item--selected, .ais-InstantSearch__root .btn-group > .btn.ais-Pagination-item--selected,
    .btn-group-vertical > .btn:focus,
    .cookieConsent .btn-group-vertical > button:focus,
    .btn-group-vertical > .btn:active,
    .cookieConsent .btn-group-vertical > button:active,
    .btn-group-vertical > .btn.active,
    .cookieConsent .btn-group-vertical > button.active,
    .cookieConsent .ais-InstantSearch__root .btn-group-vertical > button.ais-Pagination-item--selected,
    .ais-InstantSearch__root .cookieConsent .btn-group-vertical > button.ais-Pagination-item--selected,
    .ais-InstantSearch__root .btn-group-vertical > .btn.ais-Pagination-item--selected {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child), .cookieConsent .btn-group > button:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .cookieConsent .btn-group > button:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.cookieConsent .btn-group > .btn-group:not(:last-child) > button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child), .cookieConsent .btn-group > button:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn,
.cookieConsent .btn-group > .btn-group:not(:first-child) > button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .cookieConsent .btn-group-sm > button + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .cookieConsent .btn-group-lg > button + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  
  .btn-group-vertical > .btn,
  .cookieConsent .btn-group-vertical > button,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child), .cookieConsent .btn-group-vertical > button:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .cookieConsent .btn-group-vertical > button:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn,
  .cookieConsent .btn-group-vertical > .btn-group:not(:last-child) > button {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child), .cookieConsent .btn-group-vertical > button:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn,
  .cookieConsent .btn-group-vertical > .btn-group:not(:first-child) > button {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn, .cookieConsent .btn-group-toggle > button,
.btn-group-toggle > .btn-group > .btn,
.cookieConsent .btn-group-toggle > .btn-group > button {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"], .cookieConsent .btn-group-toggle > button input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .cookieConsent .btn-group-toggle > button input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .cookieConsent .btn-group-toggle > .btn-group > button input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"],
  .cookieConsent .btn-group-toggle > .btn-group > button input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control, .ais-InstantSearch__root .input-group > .ais-SortBy-select, .AutoCompleteSearch .input-group > .react-autosuggest__input, .Checkout .input-group > .StripeElement,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control, .ais-InstantSearch__root .input-group > .ais-SortBy-select + .form-control, .AutoCompleteSearch .input-group > .react-autosuggest__input + .form-control, .Checkout .input-group > .StripeElement + .form-control, .ais-InstantSearch__root .input-group > .form-control + .ais-SortBy-select, .ais-InstantSearch__root .input-group > .ais-SortBy-select + .ais-SortBy-select, .AutoCompleteSearch .ais-InstantSearch__root .input-group > .react-autosuggest__input + .ais-SortBy-select, .ais-InstantSearch__root .AutoCompleteSearch .input-group > .react-autosuggest__input + .ais-SortBy-select, .Checkout .ais-InstantSearch__root .input-group > .StripeElement + .ais-SortBy-select, .ais-InstantSearch__root .Checkout .input-group > .StripeElement + .ais-SortBy-select, .AutoCompleteSearch .input-group > .form-control + .react-autosuggest__input, .ais-InstantSearch__root .AutoCompleteSearch .input-group > .ais-SortBy-select + .react-autosuggest__input, .AutoCompleteSearch .ais-InstantSearch__root .input-group > .ais-SortBy-select + .react-autosuggest__input, .AutoCompleteSearch .input-group > .react-autosuggest__input + .react-autosuggest__input, .Checkout .AutoCompleteSearch .input-group > .StripeElement + .react-autosuggest__input, .AutoCompleteSearch .Checkout .input-group > .StripeElement + .react-autosuggest__input, .Checkout .input-group > .form-control + .StripeElement, .ais-InstantSearch__root .Checkout .input-group > .ais-SortBy-select + .StripeElement, .Checkout .ais-InstantSearch__root .input-group > .ais-SortBy-select + .StripeElement, .AutoCompleteSearch .Checkout .input-group > .react-autosuggest__input + .StripeElement, .Checkout .AutoCompleteSearch .input-group > .react-autosuggest__input + .StripeElement, .Checkout .input-group > .StripeElement + .StripeElement,
    .input-group > .form-control + .custom-select,
    .ais-InstantSearch__root .input-group > .ais-SortBy-select + .custom-select,
    .AutoCompleteSearch .input-group > .react-autosuggest__input + .custom-select,
    .Checkout .input-group > .StripeElement + .custom-select,
    .input-group > .form-control + .custom-file,
    .ais-InstantSearch__root .input-group > .ais-SortBy-select + .custom-file,
    .AutoCompleteSearch .input-group > .react-autosuggest__input + .custom-file,
    .Checkout .input-group > .StripeElement + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .ais-InstantSearch__root .input-group > .form-control-plaintext + .ais-SortBy-select,
    .AutoCompleteSearch .input-group > .form-control-plaintext + .react-autosuggest__input,
    .Checkout .input-group > .form-control-plaintext + .StripeElement,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .ais-InstantSearch__root .input-group > .custom-select + .ais-SortBy-select,
    .AutoCompleteSearch .input-group > .custom-select + .react-autosuggest__input,
    .Checkout .input-group > .custom-select + .StripeElement,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .ais-InstantSearch__root .input-group > .custom-file + .ais-SortBy-select,
    .AutoCompleteSearch .input-group > .custom-file + .react-autosuggest__input,
    .Checkout .input-group > .custom-file + .StripeElement,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus, .ais-InstantSearch__root .input-group > .ais-SortBy-select:focus, .AutoCompleteSearch .input-group > .react-autosuggest__input:focus, .Checkout .input-group > .StripeElement:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child), .ais-InstantSearch__root .input-group > .ais-SortBy-select:not(:last-child), .AutoCompleteSearch .input-group > .react-autosuggest__input:not(:last-child), .Checkout .input-group > .StripeElement:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child), .ais-InstantSearch__root .input-group > .ais-SortBy-select:not(:first-child), .AutoCompleteSearch .input-group > .react-autosuggest__input:not(:first-child), .Checkout .input-group > .StripeElement:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn, .input-group-prepend .cookieConsent button, .cookieConsent .input-group-prepend button,
  .input-group-append .btn,
  .input-group-append .cookieConsent button,
  .cookieConsent .input-group-append button {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus, .input-group-prepend .cookieConsent button:focus, .cookieConsent .input-group-prepend button:focus,
    .input-group-append .btn:focus,
    .input-group-append .cookieConsent button:focus,
    .cookieConsent .input-group-append button:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn, .input-group-prepend .cookieConsent button + .btn, .cookieConsent .input-group-prepend button + .btn, .input-group-prepend .cookieConsent .btn + button, .cookieConsent .input-group-prepend .btn + button, .input-group-prepend .cookieConsent button + button, .cookieConsent .input-group-prepend button + button,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .cookieConsent button + .input-group-text,
  .cookieConsent .input-group-prepend button + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-prepend .cookieConsent .input-group-text + button,
  .cookieConsent .input-group-prepend .input-group-text + button,
  .input-group-append .btn + .btn,
  .input-group-append .cookieConsent button + .btn,
  .cookieConsent .input-group-append button + .btn,
  .input-group-append .cookieConsent .btn + button,
  .cookieConsent .input-group-append .btn + button,
  .input-group-append .cookieConsent button + button,
  .cookieConsent .input-group-append button + button,
  .input-group-append .btn + .input-group-text,
  .input-group-append .cookieConsent button + .input-group-text,
  .cookieConsent .input-group-append button + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn,
  .input-group-append .cookieConsent .input-group-text + button,
  .cookieConsent .input-group-append .input-group-text + button {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  color: #747474;
  text-align: center;
  white-space: nowrap;
  background-color: #f3f3f3;
  border: 1px solid #cccccc;
  border-radius: 0.375rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea), .ais-InstantSearch__root .input-group-lg > .ais-SortBy-select:not(textarea), .AutoCompleteSearch .input-group-lg > .react-autosuggest__input:not(textarea), .Checkout .input-group-lg > .StripeElement:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control, .ais-InstantSearch__root .input-group-lg > .ais-SortBy-select, .AutoCompleteSearch .input-group-lg > .react-autosuggest__input, .Checkout .input-group-lg > .StripeElement,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.cookieConsent .input-group-lg > .input-group-prepend > button,
.input-group-lg > .input-group-append > .btn,
.cookieConsent .input-group-lg > .input-group-append > button {
  padding: 0.65rem 1rem;
  font-size: 1.1rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea), .ais-InstantSearch__root .input-group-sm > .ais-SortBy-select:not(textarea), .AutoCompleteSearch .input-group-sm > .react-autosuggest__input:not(textarea), .Checkout .input-group-sm > .StripeElement:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control, .ais-InstantSearch__root .input-group-sm > .ais-SortBy-select, .AutoCompleteSearch .input-group-sm > .react-autosuggest__input, .Checkout .input-group-sm > .StripeElement,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.cookieConsent .input-group-sm > .input-group-prepend > button,
.input-group-sm > .input-group-append > .btn,
.cookieConsent .input-group-sm > .input-group-append > button {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn, .cookieConsent .input-group > .input-group-prepend > button,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.cookieConsent .input-group > .input-group-append:not(:last-child) > button,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.cookieConsent .input-group > .input-group-append:last-child > button:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn, .cookieConsent .input-group > .input-group-append > button,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.cookieConsent .input-group > .input-group-prepend:not(:first-child) > button,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.cookieConsent .input-group > .input-group-prepend:first-child > button:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.4rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #3dbfbe;
    background: #3dbfbe linear-gradient(180deg, #5ac9c8, #3dbfbe) repeat-x; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.3rem rgba(61, 191, 190, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #9cdfdf; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #c3eceb;
    border-color: #c3eceb; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #8a8a8a; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #f3f3f3; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.2rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #a0a0a0 solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.2rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.375rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #3dbfbe;
  background: #3dbfbe linear-gradient(180deg, #5ac9c8, #3dbfbe) repeat-x; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(61, 191, 190, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(61, 191, 190, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(61, 191, 190, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.2rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #a0a0a0;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(61, 191, 190, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.4em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  color: #747474;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%235e5e5e' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #cccccc;
  border-radius: 0.375rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #9cdfdf;
    outline: 0;
    box-shadow: 0 0 0 0.3rem rgba(61, 191, 190, 0.25); }
    .custom-select:focus::-ms-value {
      color: #747474;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #8a8a8a;
    background-color: #f3f3f3; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.65rem;
  padding-bottom: 0.65rem;
  padding-left: 1rem;
  font-size: 1.1rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.4em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.4em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #9cdfdf;
    box-shadow: 0 0 0 0.3rem rgba(61, 191, 190, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #f3f3f3; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.4em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.4;
  color: #747474;
  background-color: #fff;
  border: 1px solid #cccccc;
  border-radius: 0.375rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.4em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.4;
    color: #747474;
    content: "Browse";
    background: #f3f3f3 linear-gradient(180deg, whitesmoke, #f3f3f3) repeat-x;
    border-left: inherit;
    border-radius: 0 0.375rem 0.375rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.6rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.3rem rgba(61, 191, 190, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.3rem rgba(61, 191, 190, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.3rem rgba(61, 191, 190, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background: #3dbfbe linear-gradient(180deg, #5ac9c8, #3dbfbe) repeat-x;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background: #c3eceb linear-gradient(180deg, #ccefee, #c3eceb) repeat-x; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #cccccc;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background: #3dbfbe linear-gradient(180deg, #5ac9c8, #3dbfbe) repeat-x;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
         appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background: #c3eceb linear-gradient(180deg, #ccefee, #c3eceb) repeat-x; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #cccccc;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.3rem;
    margin-left: 0.3rem;
    background: #3dbfbe linear-gradient(180deg, #5ac9c8, #3dbfbe) repeat-x;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background: #c3eceb linear-gradient(180deg, #ccefee, #c3eceb) repeat-x; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #cccccc;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #cccccc;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #a0a0a0; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #a0a0a0; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #a0a0a0; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #8a8a8a;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #cccccc; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #f3f3f3 #f3f3f3 #cccccc; }
    .nav-tabs .nav-link.disabled {
      color: #8a8a8a;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active, .nav-tabs .ais-InstantSearch__root .nav-link.ais-Pagination-item--selected, .ais-InstantSearch__root .nav-tabs .nav-link.ais-Pagination-item--selected,
  .nav-tabs .nav-item.show .nav-link {
    color: #747474;
    background-color: #fff;
    border-color: #cccccc #cccccc #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.375rem; }

.nav-pills .nav-link.active, .nav-pills .ais-InstantSearch__root .nav-link.ais-Pagination-item--selected, .ais-InstantSearch__root .nav-pills .nav-link.ais-Pagination-item--selected,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #3dbfbe; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active, .ais-InstantSearch__root .tab-content > .ais-Pagination-item--selected {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.43rem;
  padding-bottom: 0.43rem;
  margin-right: 1rem;
  font-size: 1.1rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.1rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.375rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 1024.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1025px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1440.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1441px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (max-width: 1920.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1921px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl > .container,
    .navbar-expand-xxl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .ais-InstantSearch__root .ais-Pagination-item--selected > .nav-link,
.ais-InstantSearch__root .navbar-light .navbar-nav .ais-Pagination-item--selected > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .ais-InstantSearch__root .nav-link.ais-Pagination-item--selected,
.ais-InstantSearch__root .navbar-light .navbar-nav .nav-link.ais-Pagination-item--selected {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .ais-InstantSearch__root .ais-Pagination-item--selected > .nav-link,
.ais-InstantSearch__root .navbar-dark .navbar-nav .ais-Pagination-item--selected > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .ais-InstantSearch__root .nav-link.ais-Pagination-item--selected,
.ais-InstantSearch__root .navbar-dark .navbar-nav .nav-link.ais-Pagination-item--selected {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.375rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.375rem - 1px) calc(0.375rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.375rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.375rem - 1px);
  border-top-right-radius: calc(0.375rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.375rem - 1px);
  border-bottom-left-radius: calc(0.375rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #f6f6f6;
  border-radius: 0.375rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #8a8a8a;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active, .ais-InstantSearch__root .breadcrumb-item.ais-Pagination-item--selected {
  color: #8a8a8a; }

.pagination, .ais-InstantSearch__root .ais-Pagination-list {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.375rem; }

.page-link, .ais-InstantSearch__root .ais-Pagination-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #3dbfbe;
  background-color: #fff;
  border: 1px solid #cccccc; }
  .page-link:hover, .ais-InstantSearch__root .ais-Pagination-link:hover {
    z-index: 2;
    color: #2a8584;
    text-decoration: none;
    background-color: #f3f3f3;
    border-color: #cccccc; }
  .page-link:focus, .ais-InstantSearch__root .ais-Pagination-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.3rem rgba(61, 191, 190, 0.25); }

.page-item:first-child .page-link, .ais-InstantSearch__root .ais-Pagination-item:first-child .page-link, .page-item:first-child .ais-InstantSearch__root .ais-Pagination-link, .ais-InstantSearch__root .page-item:first-child .ais-Pagination-link, .ais-InstantSearch__root .ais-Pagination-item:first-child .ais-Pagination-link {
  margin-left: 0;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem; }

.page-item:last-child .page-link, .ais-InstantSearch__root .ais-Pagination-item:last-child .page-link, .page-item:last-child .ais-InstantSearch__root .ais-Pagination-link, .ais-InstantSearch__root .page-item:last-child .ais-Pagination-link, .ais-InstantSearch__root .ais-Pagination-item:last-child .ais-Pagination-link {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem; }

.page-item.active .page-link, .ais-InstantSearch__root .active.ais-Pagination-item .page-link, .ais-InstantSearch__root .ais-Pagination-item.ais-Pagination-item--selected .page-link, .ais-InstantSearch__root .page-item.ais-Pagination-item--selected .page-link, .page-item.active .ais-InstantSearch__root .ais-Pagination-link, .ais-InstantSearch__root .page-item.active .ais-Pagination-link, .ais-InstantSearch__root .active.ais-Pagination-item .ais-Pagination-link, .ais-InstantSearch__root .ais-Pagination-item.ais-Pagination-item--selected .ais-Pagination-link, .ais-InstantSearch__root .page-item.ais-Pagination-item--selected .ais-Pagination-link {
  z-index: 1;
  color: #fff;
  background-color: #3dbfbe;
  border-color: #3dbfbe; }

.page-item.disabled .page-link, .ais-InstantSearch__root .disabled.ais-Pagination-item .page-link, .page-item.disabled .ais-InstantSearch__root .ais-Pagination-link, .ais-InstantSearch__root .page-item.disabled .ais-Pagination-link, .ais-InstantSearch__root .disabled.ais-Pagination-item .ais-Pagination-link {
  color: #8a8a8a;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #cccccc; }

.pagination-lg .page-link, .pagination-lg .ais-InstantSearch__root .ais-Pagination-link, .ais-InstantSearch__root .pagination-lg .ais-Pagination-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link, .pagination-lg .ais-InstantSearch__root .ais-Pagination-item:first-child .page-link, .ais-InstantSearch__root .pagination-lg .ais-Pagination-item:first-child .page-link, .pagination-lg .page-item:first-child .ais-InstantSearch__root .ais-Pagination-link, .ais-InstantSearch__root .pagination-lg .page-item:first-child .ais-Pagination-link, .pagination-lg .ais-InstantSearch__root .ais-Pagination-item:first-child .ais-Pagination-link, .ais-InstantSearch__root .pagination-lg .ais-Pagination-item:first-child .ais-Pagination-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link, .pagination-lg .ais-InstantSearch__root .ais-Pagination-item:last-child .page-link, .ais-InstantSearch__root .pagination-lg .ais-Pagination-item:last-child .page-link, .pagination-lg .page-item:last-child .ais-InstantSearch__root .ais-Pagination-link, .ais-InstantSearch__root .pagination-lg .page-item:last-child .ais-Pagination-link, .pagination-lg .ais-InstantSearch__root .ais-Pagination-item:last-child .ais-Pagination-link, .ais-InstantSearch__root .pagination-lg .ais-Pagination-item:last-child .ais-Pagination-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link, .pagination-sm .ais-InstantSearch__root .ais-Pagination-link, .ais-InstantSearch__root .pagination-sm .ais-Pagination-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link, .pagination-sm .ais-InstantSearch__root .ais-Pagination-item:first-child .page-link, .ais-InstantSearch__root .pagination-sm .ais-Pagination-item:first-child .page-link, .pagination-sm .page-item:first-child .ais-InstantSearch__root .ais-Pagination-link, .ais-InstantSearch__root .pagination-sm .page-item:first-child .ais-Pagination-link, .pagination-sm .ais-InstantSearch__root .ais-Pagination-item:first-child .ais-Pagination-link, .ais-InstantSearch__root .pagination-sm .ais-Pagination-item:first-child .ais-Pagination-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link, .pagination-sm .ais-InstantSearch__root .ais-Pagination-item:last-child .page-link, .ais-InstantSearch__root .pagination-sm .ais-Pagination-item:last-child .page-link, .pagination-sm .page-item:last-child .ais-InstantSearch__root .ais-Pagination-link, .ais-InstantSearch__root .pagination-sm .page-item:last-child .ais-Pagination-link, .pagination-sm .ais-InstantSearch__root .ais-Pagination-item:last-child .ais-Pagination-link, .ais-InstantSearch__root .pagination-sm .ais-Pagination-item:last-child .ais-Pagination-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.375rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge, .cookieConsent button .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #484848;
  background-color: #3dbfbe; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #484848;
    background-color: #319898; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.3rem rgba(61, 191, 190, 0.5); }

.badge-secondary {
  color: #484848;
  background-color: #f47b30; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #484848;
    background-color: #e55f0c; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.3rem rgba(244, 123, 48, 0.5); }

.badge-success {
  color: #fff;
  background-color: #32b734; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #278f29; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.3rem rgba(50, 183, 52, 0.5); }

.badge-info {
  color: #fff;
  background-color: #328fe2; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #1c75c5; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.3rem rgba(50, 143, 226, 0.5); }

.badge-warning {
  color: #484848;
  background-color: #ffbf00; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #484848;
    background-color: #cc9900; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.3rem rgba(255, 191, 0, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #f54156; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #f2112b; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.3rem rgba(245, 65, 86, 0.5); }

.badge-light {
  color: #484848;
  background-color: #f3f3f3; }
  a.badge-light:hover, a.badge-light:focus {
    color: #484848;
    background-color: #dadada; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.3rem rgba(243, 243, 243, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #5e5e5e; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #454545; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.3rem rgba(94, 94, 94, 0.5); }

.badge-white {
  color: #484848;
  background-color: #fff; }
  a.badge-white:hover, a.badge-white:focus {
    color: #484848;
    background-color: #e6e6e6; }
  a.badge-white:focus, a.badge-white.focus {
    outline: 0;
    box-shadow: 0 0 0 0.3rem rgba(255, 255, 255, 0.5); }

.badge-black {
  color: #fff;
  background-color: #000; }
  a.badge-black:hover, a.badge-black:focus {
    color: #fff;
    background-color: black; }
  a.badge-black:focus, a.badge-black.focus {
    outline: 0;
    box-shadow: 0 0 0 0.3rem rgba(0, 0, 0, 0.5); }

.badge-purple {
  color: #fff;
  background-color: #933ddf; }
  a.badge-purple:hover, a.badge-purple:focus {
    color: #fff;
    background-color: #7a21c8; }
  a.badge-purple:focus, a.badge-purple.focus {
    outline: 0;
    box-shadow: 0 0 0 0.3rem rgba(147, 61, 223, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #f3f3f3;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.375rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3.9rem; }
  .alert-dismissible .close, .alert-dismissible .ais-InstantSearch__root .ais-CurrentRefinements-delete, .ais-InstantSearch__root .alert-dismissible .ais-CurrentRefinements-delete {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #206363;
  background: #d8f2f2 linear-gradient(180deg, #def4f4, #d8f2f2) repeat-x;
  border-color: #c9eded; }
  .alert-primary hr {
    border-top-color: #b6e7e7; }
  .alert-primary .alert-link {
    color: #143c3c; }

.alert-secondary {
  color: #7f4019;
  background: #fde5d6 linear-gradient(180deg, #fde9dc, #fde5d6) repeat-x;
  border-color: #fcdac5; }
  .alert-secondary hr {
    border-top-color: #fbcbad; }
  .alert-secondary .alert-link {
    color: #542b11; }

.alert-success {
  color: #1a5f1b;
  background: #d6f1d6 linear-gradient(180deg, #dcf3dc, #d6f1d6) repeat-x;
  border-color: #c6ebc6; }
  .alert-success hr {
    border-top-color: #b3e4b3; }
  .alert-success .alert-link {
    color: #0f3710; }

.alert-info {
  color: #1a4a76;
  background: #d6e9f9 linear-gradient(180deg, #dcecfa, #d6e9f9) repeat-x;
  border-color: #c6e0f7; }
  .alert-info hr {
    border-top-color: #b0d4f4; }
  .alert-info .alert-link {
    color: #11304c; }

.alert-warning {
  color: #856300;
  background: #fff2cc linear-gradient(180deg, #fff4d4, #fff2cc) repeat-x;
  border-color: #ffedb8; }
  .alert-warning hr {
    border-top-color: #ffe79f; }
  .alert-warning .alert-link {
    color: #523d00; }

.alert-danger {
  color: #7f222d;
  background: #fdd9dd linear-gradient(180deg, #fddfe2, #fdd9dd) repeat-x;
  border-color: #fccad0; }
  .alert-danger hr {
    border-top-color: #fbb2bb; }
  .alert-danger .alert-link {
    color: #57171f; }

.alert-light {
  color: #7e7e7e;
  background: #fdfdfd linear-gradient(180deg, #fdfdfd, #fdfdfd) repeat-x;
  border-color: #fcfcfc; }
  .alert-light hr {
    border-top-color: #efefef; }
  .alert-light .alert-link {
    color: #656565; }

.alert-dark {
  color: #313131;
  background: #dfdfdf linear-gradient(180deg, #e4e4e4, #dfdfdf) repeat-x;
  border-color: #d2d2d2; }
  .alert-dark hr {
    border-top-color: #c5c5c5; }
  .alert-dark .alert-link {
    color: #181818; }

.alert-white {
  color: #858585;
  background: white linear-gradient(180deg, white, white) repeat-x;
  border-color: white; }
  .alert-white hr {
    border-top-color: #f2f2f2; }
  .alert-white .alert-link {
    color: #6c6c6c; }

.alert-black {
  color: black;
  background: #cccccc linear-gradient(180deg, #d4d4d4, #cccccc) repeat-x;
  border-color: #b8b8b8; }
  .alert-black hr {
    border-top-color: #ababab; }
  .alert-black .alert-link {
    color: black; }

.alert-purple {
  color: #4c2074;
  background: #e9d8f9 linear-gradient(180deg, #ecdefa, #e9d8f9) repeat-x;
  border-color: #e1c9f6; }
  .alert-purple hr {
    border-top-color: #d5b3f2; }
  .alert-purple .alert-link {
    color: #32154c; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #f3f3f3;
  border-radius: 0.375rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #3dbfbe;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #484848;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #484848;
    text-decoration: none;
    background-color: #f3f3f3; }
  .list-group-item-action:active {
    color: #484848;
    background-color: #f3f3f3; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.375rem 0.75rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #8a8a8a;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active, .ais-InstantSearch__root .list-group-item.ais-Pagination-item--selected {
    z-index: 2;
    color: #fff;
    background-color: #3dbfbe;
    border-color: #3dbfbe; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.375rem;
      border-bottom-left-radius: 0.375rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.375rem;
      border-bottom-right-radius: 0.375rem;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.375rem;
        border-bottom-left-radius: 0.375rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.375rem;
        border-bottom-right-radius: 0.375rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.375rem;
        border-bottom-left-radius: 0.375rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.375rem;
        border-bottom-right-radius: 0.375rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1025px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.375rem;
        border-bottom-left-radius: 0.375rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.375rem;
        border-bottom-right-radius: 0.375rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1441px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.375rem;
        border-bottom-left-radius: 0.375rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.375rem;
        border-bottom-right-radius: 0.375rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1921px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xxl .list-group-item:first-child {
        border-top-left-radius: 0.375rem;
        border-bottom-left-radius: 0.375rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xxl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.375rem;
        border-bottom-right-radius: 0.375rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #206363;
  background-color: #c9eded; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #206363;
    background-color: #b6e7e7; }
  .list-group-item-primary.list-group-item-action.active, .ais-InstantSearch__root .list-group-item-primary.list-group-item-action.ais-Pagination-item--selected {
    color: #fff;
    background-color: #206363;
    border-color: #206363; }

.list-group-item-secondary {
  color: #7f4019;
  background-color: #fcdac5; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #7f4019;
    background-color: #fbcbad; }
  .list-group-item-secondary.list-group-item-action.active, .ais-InstantSearch__root .list-group-item-secondary.list-group-item-action.ais-Pagination-item--selected {
    color: #fff;
    background-color: #7f4019;
    border-color: #7f4019; }

.list-group-item-success {
  color: #1a5f1b;
  background-color: #c6ebc6; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #1a5f1b;
    background-color: #b3e4b3; }
  .list-group-item-success.list-group-item-action.active, .ais-InstantSearch__root .list-group-item-success.list-group-item-action.ais-Pagination-item--selected {
    color: #fff;
    background-color: #1a5f1b;
    border-color: #1a5f1b; }

.list-group-item-info {
  color: #1a4a76;
  background-color: #c6e0f7; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #1a4a76;
    background-color: #b0d4f4; }
  .list-group-item-info.list-group-item-action.active, .ais-InstantSearch__root .list-group-item-info.list-group-item-action.ais-Pagination-item--selected {
    color: #fff;
    background-color: #1a4a76;
    border-color: #1a4a76; }

.list-group-item-warning {
  color: #856300;
  background-color: #ffedb8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856300;
    background-color: #ffe79f; }
  .list-group-item-warning.list-group-item-action.active, .ais-InstantSearch__root .list-group-item-warning.list-group-item-action.ais-Pagination-item--selected {
    color: #fff;
    background-color: #856300;
    border-color: #856300; }

.list-group-item-danger {
  color: #7f222d;
  background-color: #fccad0; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #7f222d;
    background-color: #fbb2bb; }
  .list-group-item-danger.list-group-item-action.active, .ais-InstantSearch__root .list-group-item-danger.list-group-item-action.ais-Pagination-item--selected {
    color: #fff;
    background-color: #7f222d;
    border-color: #7f222d; }

.list-group-item-light {
  color: #7e7e7e;
  background-color: #fcfcfc; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #7e7e7e;
    background-color: #efefef; }
  .list-group-item-light.list-group-item-action.active, .ais-InstantSearch__root .list-group-item-light.list-group-item-action.ais-Pagination-item--selected {
    color: #fff;
    background-color: #7e7e7e;
    border-color: #7e7e7e; }

.list-group-item-dark {
  color: #313131;
  background-color: #d2d2d2; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #313131;
    background-color: #c5c5c5; }
  .list-group-item-dark.list-group-item-action.active, .ais-InstantSearch__root .list-group-item-dark.list-group-item-action.ais-Pagination-item--selected {
    color: #fff;
    background-color: #313131;
    border-color: #313131; }

.list-group-item-white {
  color: #858585;
  background-color: white; }
  .list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-white.list-group-item-action.active, .ais-InstantSearch__root .list-group-item-white.list-group-item-action.ais-Pagination-item--selected {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-black {
  color: black;
  background-color: #b8b8b8; }
  .list-group-item-black.list-group-item-action:hover, .list-group-item-black.list-group-item-action:focus {
    color: black;
    background-color: #ababab; }
  .list-group-item-black.list-group-item-action.active, .ais-InstantSearch__root .list-group-item-black.list-group-item-action.ais-Pagination-item--selected {
    color: #fff;
    background-color: black;
    border-color: black; }

.list-group-item-purple {
  color: #4c2074;
  background-color: #e1c9f6; }
  .list-group-item-purple.list-group-item-action:hover, .list-group-item-purple.list-group-item-action:focus {
    color: #4c2074;
    background-color: #d5b3f2; }
  .list-group-item-purple.list-group-item-action.active, .ais-InstantSearch__root .list-group-item-purple.list-group-item-action.ais-Pagination-item--selected {
    color: #fff;
    background-color: #4c2074;
    border-color: #4c2074; }

.close, .ais-InstantSearch__root .ais-CurrentRefinements-delete {
  float: right;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1;
  color: #000;
  text-shadow: none;
  opacity: .5; }
  .close:hover, .ais-InstantSearch__root .ais-CurrentRefinements-delete:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .ais-InstantSearch__root .ais-CurrentRefinements-delete:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus, .ais-InstantSearch__root .ais-CurrentRefinements-delete:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close, .ais-InstantSearch__root button.ais-CurrentRefinements-delete {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

a.close.disabled, .ais-InstantSearch__root a.disabled.ais-CurrentRefinements-delete {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #8a8a8a;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 0 solid #cccccc;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close, .modal-header .ais-InstantSearch__root .ais-CurrentRefinements-delete, .ais-InstantSearch__root .modal-header .ais-CurrentRefinements-delete {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.4; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 3rem 2.5rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 3rem 2.5rem;
  border-top: 0 solid #cccccc;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 1025px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1441px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.375rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.15); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.15); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.15); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.15); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #484848; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active, .ais-InstantSearch__root .carousel-item.ais-Pagination-item--selected,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right,
.ais-InstantSearch__root .carousel-item-right.ais-Pagination-item--selected {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left,
.ais-InstantSearch__root .carousel-item-left.ais-Pagination-item--selected {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active, .carousel-fade .ais-InstantSearch__root .carousel-item.ais-Pagination-item--selected, .ais-InstantSearch__root .carousel-fade .carousel-item.ais-Pagination-item--selected,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left, .carousel-fade .ais-InstantSearch__root .carousel-item-left.ais-Pagination-item--selected, .ais-InstantSearch__root .carousel-fade .carousel-item-left.ais-Pagination-item--selected,
.carousel-fade .active.carousel-item-right,
.carousel-fade .ais-InstantSearch__root .carousel-item-right.ais-Pagination-item--selected,
.ais-InstantSearch__root .carousel-fade .carousel-item-right.ais-Pagination-item--selected {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left, .carousel-fade .ais-InstantSearch__root .carousel-item-left.ais-Pagination-item--selected, .ais-InstantSearch__root .carousel-fade .carousel-item-left.ais-Pagination-item--selected,
    .carousel-fade .active.carousel-item-right,
    .carousel-fade .ais-InstantSearch__root .carousel-item-right.ais-Pagination-item--selected,
    .ais-InstantSearch__root .carousel-fade .carousel-item-right.ais-Pagination-item--selected {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.001)); }

.carousel-control-next {
  right: 0;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.001)); }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active, .carousel-indicators .ais-InstantSearch__root .ais-Pagination-item--selected, .ais-InstantSearch__root .carousel-indicators .ais-Pagination-item--selected {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #3dbfbe !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #319898 !important; }

.bg-secondary {
  background-color: #f47b30 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #e55f0c !important; }

.bg-success {
  background-color: #32b734 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #278f29 !important; }

.bg-info {
  background-color: #328fe2 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #1c75c5 !important; }

.bg-warning {
  background-color: #ffbf00 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cc9900 !important; }

.bg-danger {
  background-color: #f54156 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #f2112b !important; }

.bg-light {
  background-color: #f3f3f3 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dadada !important; }

.bg-dark {
  background-color: #5e5e5e !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #454545 !important; }

.bg-white {
  background-color: #fff !important; }

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important; }

.bg-black {
  background-color: #000 !important; }

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: black !important; }

.bg-purple {
  background-color: #933ddf !important; }

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #7a21c8 !important; }

.bg-gradient-primary {
  background: #3dbfbe linear-gradient(180deg, #5ac9c8, #3dbfbe) repeat-x !important; }

.bg-gradient-secondary {
  background: #f47b30 linear-gradient(180deg, #f68f4f, #f47b30) repeat-x !important; }

.bg-gradient-success {
  background: #32b734 linear-gradient(180deg, #51c252, #32b734) repeat-x !important; }

.bg-gradient-info {
  background: #328fe2 linear-gradient(180deg, #51a0e6, #328fe2) repeat-x !important; }

.bg-gradient-warning {
  background: #ffbf00 linear-gradient(180deg, #ffc926, #ffbf00) repeat-x !important; }

.bg-gradient-danger {
  background: #f54156 linear-gradient(180deg, #f75e6f, #f54156) repeat-x !important; }

.bg-gradient-light {
  background: #f3f3f3 linear-gradient(180deg, whitesmoke, #f3f3f3) repeat-x !important; }

.bg-gradient-dark {
  background: #5e5e5e linear-gradient(180deg, #767676, #5e5e5e) repeat-x !important; }

.bg-gradient-white {
  background: #fff linear-gradient(180deg, white, #fff) repeat-x !important; }

.bg-gradient-black {
  background: #000 linear-gradient(180deg, #262626, #000) repeat-x !important; }

.bg-gradient-purple {
  background: #933ddf linear-gradient(180deg, #a35ae4, #933ddf) repeat-x !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #cccccc !important; }

.border-top {
  border-top: 1px solid #cccccc !important; }

.border-right, .PageMenu ul {
  border-right: 1px solid #cccccc !important; }

.border-bottom {
  border-bottom: 1px solid #cccccc !important; }

.border-left {
  border-left: 1px solid #cccccc !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #3dbfbe !important; }

.border-secondary {
  border-color: #f47b30 !important; }

.border-success {
  border-color: #32b734 !important; }

.border-info {
  border-color: #328fe2 !important; }

.border-warning {
  border-color: #ffbf00 !important; }

.border-danger {
  border-color: #f54156 !important; }

.border-light {
  border-color: #f3f3f3 !important; }

.border-dark {
  border-color: #5e5e5e !important; }

.border-white {
  border-color: #fff !important; }

.border-black {
  border-color: #000 !important; }

.border-purple {
  border-color: #933ddf !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.375rem !important; }

.rounded-top {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important; }

.rounded-right {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important; }

.rounded-left {
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block, .teetime-summary .teetime-summary-description {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex, .slick-track, .AutoCompleteSearch .react-autosuggest__suggestion, .Loader, .Start__header-menu {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1025px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1441px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1921px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column, .Start__header-menu {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center, .ais-InstantSearch__root .ais-Pagination-list, .Loader {
  justify-content: center !important; }

.justify-content-between, .Start__header-menu {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start, .AutoCompleteSearch .react-autosuggest__suggestion {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center, .slick-track, .Loader {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1025px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1441px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1921px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1025px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1441px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

@media (min-width: 1921px) {
  .float-xxl-left {
    float: left !important; }
  .float-xxl-right {
    float: right !important; }
  .float-xxl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75, .SearchHit .card-img-top.no-image img {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0, .form-group .form-group, .AutoCompleteSearch .react-autosuggest__suggestion p, .StartGuideStep p,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1, .teetime-summary .teetime-summary-description,
.my-1,
.USPList li {
  margin-top: 0.25rem !important; }

.mr-1, .AutoCompleteSearch .react-autosuggest__suggestion__icon-container,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1, .AutoCompleteSearch .react-autosuggest__section-title,
.my-1,
.USPList li {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2,
.ais-InstantSearch__root .ais-Hits-item,
.AutoCompleteSearch .react-autosuggest__section-container,
.PageMenu li {
  margin-top: 0.5rem !important; }

.mr-2, .ais-InstantSearch__root .ais-CurrentRefinements-item,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2, .teetime-summary, .ais-InstantSearch__root .ais-CurrentRefinements-item, .StartGuideStep strong,
.my-2,
.ais-InstantSearch__root .ais-Hits-item,
.AutoCompleteSearch .react-autosuggest__section-container,
.PageMenu li {
  margin-bottom: 0.5rem !important; }

.ml-2, .ais-InstantSearch__root .ais-CurrentRefinements-category,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3,
.AutoCompleteSearch .react-autosuggest__section-title {
  margin-right: 1rem !important; }

.mb-3, .CustomAlert, .form-logo, .slick-list, .Footer__credit-cards li,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3,
.AutoCompleteSearch .react-autosuggest__section-title {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4, .PageMenu ul, .Search .ais-Hits-item,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.m-6 {
  margin: 4rem !important; }

.mt-6,
.my-6 {
  margin-top: 4rem !important; }

.mr-6,
.mx-6 {
  margin-right: 4rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 4rem !important; }

.ml-6,
.mx-6 {
  margin-left: 4rem !important; }

.m-7 {
  margin: 5rem !important; }

.mt-7,
.my-7 {
  margin-top: 5rem !important; }

.mr-7,
.mx-7 {
  margin-right: 5rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 5rem !important; }

.ml-7,
.mx-7 {
  margin-left: 5rem !important; }

.m-8 {
  margin: 8rem !important; }

.mt-8,
.my-8 {
  margin-top: 8rem !important; }

.mr-8,
.mx-8 {
  margin-right: 8rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 8rem !important; }

.ml-8,
.mx-8 {
  margin-left: 8rem !important; }

.p-0, .popover .popover-body {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1,
.AutoCompleteSearch .react-autosuggest__suggestion {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1,
.AutoCompleteSearch .react-autosuggest__suggestions-container {
  padding-right: 0.25rem !important; }

.pb-1, .AutoCompleteSearch .react-autosuggest__section-title,
.py-1,
.AutoCompleteSearch .react-autosuggest__suggestion {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1,
.AutoCompleteSearch .react-autosuggest__suggestions-container {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2,
.AutoCompleteSearch .react-autosuggest__suggestions-container--open {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2,
.USPList li {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2,
.AutoCompleteSearch .react-autosuggest__suggestions-container--open {
  padding-bottom: 0.5rem !important; }

.pl-2, .PageMenu li,
.px-2,
.USPList li {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3,
.CheckoutCartClubRow {
  padding-top: 1rem !important; }

.pr-3,
.px-3,
.AutoCompleteSearch .react-autosuggest__suggestion,
.CheckoutCartClubRow {
  padding-right: 1rem !important; }

.pb-3,
.py-3,
.CheckoutCartClubRow {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3,
.AutoCompleteSearch .react-autosuggest__suggestion,
.CheckoutCartClubRow {
  padding-left: 1rem !important; }

.p-4, .SearchHit .card-img-top.no-image {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.p-6 {
  padding: 4rem !important; }

.pt-6,
.py-6 {
  padding-top: 4rem !important; }

.pr-6,
.px-6 {
  padding-right: 4rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 4rem !important; }

.pl-6,
.px-6 {
  padding-left: 4rem !important; }

.p-7 {
  padding: 5rem !important; }

.pt-7,
.py-7 {
  padding-top: 5rem !important; }

.pr-7,
.px-7 {
  padding-right: 5rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 5rem !important; }

.pl-7,
.px-7 {
  padding-left: 5rem !important; }

.p-8 {
  padding: 8rem !important; }

.pt-8,
.py-8 {
  padding-top: 8rem !important; }

.pr-8,
.px-8 {
  padding-right: 8rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 8rem !important; }

.pl-8,
.px-8 {
  padding-left: 8rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-n6 {
  margin: -4rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -4rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -4rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -4rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -4rem !important; }

.m-n7 {
  margin: -5rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -5rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -5rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -5rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -5rem !important; }

.m-n8 {
  margin: -8rem !important; }

.mt-n8,
.my-n8 {
  margin-top: -8rem !important; }

.mr-n8,
.mx-n8 {
  margin-right: -8rem !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -8rem !important; }

.ml-n8,
.mx-n8 {
  margin-left: -8rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto,
.ContentBlock__separator,
.Start .AutoCompleteSearch {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto,
.ContentBlock__separator,
.Start .AutoCompleteSearch {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .m-sm-6 {
    margin: 4rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4rem !important; }
  .m-sm-7 {
    margin: 5rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 5rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 5rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 5rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 5rem !important; }
  .m-sm-8 {
    margin: 8rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 8rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 8rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 8rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 8rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .p-sm-6 {
    padding: 4rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4rem !important; }
  .p-sm-7 {
    padding: 5rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 5rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 5rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 5rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 5rem !important; }
  .p-sm-8 {
    padding: 8rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 8rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 8rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 8rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 8rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-n6 {
    margin: -4rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -4rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -4rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -4rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -4rem !important; }
  .m-sm-n7 {
    margin: -5rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -5rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -5rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -5rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -5rem !important; }
  .m-sm-n8 {
    margin: -8rem !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -8rem !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -8rem !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -8rem !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -8rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .m-md-6 {
    margin: 4rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4rem !important; }
  .m-md-7 {
    margin: 5rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 5rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 5rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 5rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 5rem !important; }
  .m-md-8 {
    margin: 8rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 8rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 8rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 8rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 8rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .p-md-6 {
    padding: 4rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4rem !important; }
  .p-md-7 {
    padding: 5rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 5rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 5rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 5rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 5rem !important; }
  .p-md-8 {
    padding: 8rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 8rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 8rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 8rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 8rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-n6 {
    margin: -4rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -4rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -4rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -4rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -4rem !important; }
  .m-md-n7 {
    margin: -5rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -5rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -5rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -5rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -5rem !important; }
  .m-md-n8 {
    margin: -8rem !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -8rem !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -8rem !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -8rem !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -8rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1025px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .m-lg-6 {
    margin: 4rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4rem !important; }
  .m-lg-7 {
    margin: 5rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 5rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 5rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 5rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 5rem !important; }
  .m-lg-8 {
    margin: 8rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 8rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 8rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 8rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 8rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .p-lg-6 {
    padding: 4rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4rem !important; }
  .p-lg-7 {
    padding: 5rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 5rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 5rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 5rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 5rem !important; }
  .p-lg-8 {
    padding: 8rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 8rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 8rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 8rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 8rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-n6 {
    margin: -4rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -4rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -4rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -4rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -4rem !important; }
  .m-lg-n7 {
    margin: -5rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -5rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -5rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -5rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -5rem !important; }
  .m-lg-n8 {
    margin: -8rem !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -8rem !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -8rem !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -8rem !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -8rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1441px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .m-xl-6 {
    margin: 4rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4rem !important; }
  .m-xl-7 {
    margin: 5rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 5rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 5rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 5rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 5rem !important; }
  .m-xl-8 {
    margin: 8rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 8rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 8rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 8rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 8rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .p-xl-6 {
    padding: 4rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4rem !important; }
  .p-xl-7 {
    padding: 5rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 5rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 5rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 5rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 5rem !important; }
  .p-xl-8 {
    padding: 8rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 8rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 8rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 8rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 8rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-n6 {
    margin: -4rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -4rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -4rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -4rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -4rem !important; }
  .m-xl-n7 {
    margin: -5rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -5rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -5rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -5rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -5rem !important; }
  .m-xl-n8 {
    margin: -8rem !important; }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -8rem !important; }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -8rem !important; }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -8rem !important; }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -8rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1921px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }
  .m-xxl-6 {
    margin: 4rem !important; }
  .mt-xxl-6,
  .my-xxl-6 {
    margin-top: 4rem !important; }
  .mr-xxl-6,
  .mx-xxl-6 {
    margin-right: 4rem !important; }
  .mb-xxl-6,
  .my-xxl-6 {
    margin-bottom: 4rem !important; }
  .ml-xxl-6,
  .mx-xxl-6 {
    margin-left: 4rem !important; }
  .m-xxl-7 {
    margin: 5rem !important; }
  .mt-xxl-7,
  .my-xxl-7 {
    margin-top: 5rem !important; }
  .mr-xxl-7,
  .mx-xxl-7 {
    margin-right: 5rem !important; }
  .mb-xxl-7,
  .my-xxl-7 {
    margin-bottom: 5rem !important; }
  .ml-xxl-7,
  .mx-xxl-7 {
    margin-left: 5rem !important; }
  .m-xxl-8 {
    margin: 8rem !important; }
  .mt-xxl-8,
  .my-xxl-8 {
    margin-top: 8rem !important; }
  .mr-xxl-8,
  .mx-xxl-8 {
    margin-right: 8rem !important; }
  .mb-xxl-8,
  .my-xxl-8 {
    margin-bottom: 8rem !important; }
  .ml-xxl-8,
  .mx-xxl-8 {
    margin-left: 8rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }
  .p-xxl-6 {
    padding: 4rem !important; }
  .pt-xxl-6,
  .py-xxl-6 {
    padding-top: 4rem !important; }
  .pr-xxl-6,
  .px-xxl-6 {
    padding-right: 4rem !important; }
  .pb-xxl-6,
  .py-xxl-6 {
    padding-bottom: 4rem !important; }
  .pl-xxl-6,
  .px-xxl-6 {
    padding-left: 4rem !important; }
  .p-xxl-7 {
    padding: 5rem !important; }
  .pt-xxl-7,
  .py-xxl-7 {
    padding-top: 5rem !important; }
  .pr-xxl-7,
  .px-xxl-7 {
    padding-right: 5rem !important; }
  .pb-xxl-7,
  .py-xxl-7 {
    padding-bottom: 5rem !important; }
  .pl-xxl-7,
  .px-xxl-7 {
    padding-left: 5rem !important; }
  .p-xxl-8 {
    padding: 8rem !important; }
  .pt-xxl-8,
  .py-xxl-8 {
    padding-top: 8rem !important; }
  .pr-xxl-8,
  .px-xxl-8 {
    padding-right: 8rem !important; }
  .pb-xxl-8,
  .py-xxl-8 {
    padding-bottom: 8rem !important; }
  .pl-xxl-8,
  .px-xxl-8 {
    padding-left: 8rem !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important; }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important; }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -3rem !important; }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important; }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important; }
  .m-xxl-n6 {
    margin: -4rem !important; }
  .mt-xxl-n6,
  .my-xxl-n6 {
    margin-top: -4rem !important; }
  .mr-xxl-n6,
  .mx-xxl-n6 {
    margin-right: -4rem !important; }
  .mb-xxl-n6,
  .my-xxl-n6 {
    margin-bottom: -4rem !important; }
  .ml-xxl-n6,
  .mx-xxl-n6 {
    margin-left: -4rem !important; }
  .m-xxl-n7 {
    margin: -5rem !important; }
  .mt-xxl-n7,
  .my-xxl-n7 {
    margin-top: -5rem !important; }
  .mr-xxl-n7,
  .mx-xxl-n7 {
    margin-right: -5rem !important; }
  .mb-xxl-n7,
  .my-xxl-n7 {
    margin-bottom: -5rem !important; }
  .ml-xxl-n7,
  .mx-xxl-n7 {
    margin-left: -5rem !important; }
  .m-xxl-n8 {
    margin: -8rem !important; }
  .mt-xxl-n8,
  .my-xxl-n8 {
    margin-top: -8rem !important; }
  .mr-xxl-n8,
  .mx-xxl-n8 {
    margin-right: -8rem !important; }
  .mb-xxl-n8,
  .my-xxl-n8 {
    margin-bottom: -8rem !important; }
  .ml-xxl-n8,
  .mx-xxl-n8 {
    margin-left: -8rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1025px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1441px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1921px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #3dbfbe !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #2a8584 !important; }

.text-secondary {
  color: #f47b30 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #cd550b !important; }

.text-success {
  color: #32b734 !important; }

a.text-success:hover, a.text-success:focus {
  color: #227b23 !important; }

.text-info {
  color: #328fe2 !important; }

a.text-info:hover, a.text-info:focus {
  color: #1968af !important; }

.text-warning {
  color: #ffbf00 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #b38600 !important; }

.text-danger {
  color: #f54156 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #de0c24 !important; }

.text-light {
  color: #f3f3f3 !important; }

a.text-light:hover, a.text-light:focus {
  color: #cdcdcd !important; }

.text-dark {
  color: #5e5e5e !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #383838 !important; }

.text-white {
  color: #fff !important; }

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important; }

.text-black {
  color: #000 !important; }

a.text-black:hover, a.text-black:focus {
  color: black !important; }

.text-purple {
  color: #933ddf !important; }

a.text-purple:hover, a.text-purple:focus {
  color: #6c1db2 !important; }

.text-body, .PageMenu li.active, .PageMenu .ais-InstantSearch__root li.ais-Pagination-item--selected, .ais-InstantSearch__root .PageMenu li.ais-Pagination-item--selected, .PageMenu li.active a, .PageMenu .ais-InstantSearch__root li.ais-Pagination-item--selected a, .ais-InstantSearch__root .PageMenu li.ais-Pagination-item--selected a {
  color: #484848 !important; }

.text-muted, .StartGuideStep p {
  color: #cccccc !important; }

.text-black-50, th, .teetime-summary .teetime-summary-label, .teetime-summary .teetime-summary-description, .AutoCompleteSearch .react-autosuggest__suggestion p, .PageMenu li, .PageMenu li a {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #a0a0a0;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 1025px !important; }
  .container {
    min-width: 1025px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #cccccc !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #f3f3f3; }
  .table .thead-dark th {
    color: inherit;
    border-color: #f3f3f3; } }

.alert {
  padding: 0.6rem 1.25rem 0.75rem;
  border-radius: 0; }
  .alert a {
    color: white;
    font-weight: bold;
    text-decoration: underline; }

.alert-dismissible {
  padding-right: 2.5rem; }
  .alert-dismissible .close, .alert-dismissible .ais-InstantSearch__root .ais-CurrentRefinements-delete, .ais-InstantSearch__root .alert-dismissible .ais-CurrentRefinements-delete {
    padding: 0.75rem 0.9375rem; }

.alert-primary {
  color: #ffffff;
  background: #3dbfbe linear-gradient(180deg, #5ac9c8, #3dbfbe) repeat-x;
  border-color: #3dbfbe; }
  .alert-primary hr {
    border-top-color: #37acab; }
  .alert-primary .alert-link {
    color: #e6e6e6; }

.alert-secondary {
  color: #ffffff;
  background: #f47b30 linear-gradient(180deg, #f68f4f, #f47b30) repeat-x;
  border-color: #f47b30; }
  .alert-secondary hr {
    border-top-color: #f36c18; }
  .alert-secondary .alert-link {
    color: #e6e6e6; }

.alert-success {
  color: #ffffff;
  background: #32b734 linear-gradient(180deg, #51c252, #32b734) repeat-x;
  border-color: #32b734; }
  .alert-success hr {
    border-top-color: #2da32e; }
  .alert-success .alert-link {
    color: #e6e6e6; }

.alert-info {
  color: #ffffff;
  background: #328fe2 linear-gradient(180deg, #51a0e6, #328fe2) repeat-x;
  border-color: #328fe2; }
  .alert-info hr {
    border-top-color: #1f83db; }
  .alert-info .alert-link {
    color: #e6e6e6; }

.alert-warning {
  color: #ffffff;
  background: #ffbf00 linear-gradient(180deg, #ffc926, #ffbf00) repeat-x;
  border-color: #ffbf00; }
  .alert-warning hr {
    border-top-color: #e6ac00; }
  .alert-warning .alert-link {
    color: #e6e6e6; }

.alert-danger {
  color: #ffffff;
  background: #f54156 linear-gradient(180deg, #f75e6f, #f54156) repeat-x;
  border-color: #f54156; }
  .alert-danger hr {
    border-top-color: #f42940; }
  .alert-danger .alert-link {
    color: #e6e6e6; }

.alert-light {
  color: #ffffff;
  background: #f3f3f3 linear-gradient(180deg, whitesmoke, #f3f3f3) repeat-x;
  border-color: #f3f3f3; }
  .alert-light hr {
    border-top-color: #e6e6e6; }
  .alert-light .alert-link {
    color: #e6e6e6; }

.alert-dark {
  color: #ffffff;
  background: #5e5e5e linear-gradient(180deg, #767676, #5e5e5e) repeat-x;
  border-color: #5e5e5e; }
  .alert-dark hr {
    border-top-color: #515151; }
  .alert-dark .alert-link {
    color: #e6e6e6; }

.alert-white {
  color: #ffffff;
  background: #fff linear-gradient(180deg, white, #fff) repeat-x;
  border-color: #fff; }
  .alert-white hr {
    border-top-color: #f2f2f2; }
  .alert-white .alert-link {
    color: #e6e6e6; }

.alert-black {
  color: #ffffff;
  background: #000 linear-gradient(180deg, #262626, #000) repeat-x;
  border-color: #000; }
  .alert-black hr {
    border-top-color: black; }
  .alert-black .alert-link {
    color: #e6e6e6; }

.alert-purple {
  color: #ffffff;
  background: #933ddf linear-gradient(180deg, #a35ae4, #933ddf) repeat-x;
  border-color: #933ddf; }
  .alert-purple hr {
    border-top-color: #8727db; }
  .alert-purple .alert-link {
    color: #e6e6e6; }

.alert-light {
  color: #5e5e5e; }

.badge-primary {
  color: white; }

.badge-secondary {
  color: white; }

.badge-success {
  color: white; }

.badge-info {
  color: white; }

.badge-warning {
  color: white; }

.badge-danger {
  color: white; }

.badge-dark {
  color: white; }

.badge-white {
  color: white; }

.badge-black {
  color: white; }

.badge-purple {
  color: white; }

.btn-lg, .btn-group-lg > .btn, .cookieConsent .btn-group-lg > button,
.btn-group-lg > .btn,
.cookieConsent .btn-group-lg > button {
  font-size: 1rem; }

.btn-light {
  border: none; }
  .btn-light:disabled, .btn-light.disabled {
    background-color: #f3f3f3;
    color: #b6b6b6; }

.btn-outline-light {
  color: #747474;
  border: 1px solid #cccccc; }
  .btn-outline-light:hover {
    color: inherit;
    border-color: #cccccc; }
  .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .ais-InstantSearch__root .btn-outline-light.ais-Pagination-item--selected:not(:disabled):not(.disabled):focus,
  .show > .btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.3rem rgba(61, 191, 190, 0.5); }

.btn-primary {
  color: white; }
  .btn-primary.disabled, .btn-primary:disabled {
    color: white; }
  .btn-primary:not(:disabled):not(.disabled):hover, .btn-primary:not(:disabled):not(.disabled):active {
    color: white; }
  .btn-primary.btn-lg, .btn-group-lg > .btn-primary.btn, .cookieConsent .btn-group-lg > button.btn-primary {
    border-color: #61d9d8;
    background-image: linear-gradient(to bottom, #61d9d8 0%, #3dbfbe 100%);
    background-repeat: repeat-x; }
    .btn-primary.btn-lg:active, .btn-group-lg > .btn-primary.btn:active, .cookieConsent .btn-group-lg > button.btn-primary:active, .btn-primary.btn-lg:hover, .btn-group-lg > .btn-primary.btn:hover, .cookieConsent .btn-group-lg > button.btn-primary:hover {
      border-color: #3dbfbe; }

.btn-outline-primary {
  background-color: #fff; }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    background-color: #fff;
    opacity: 0.25; }
  .btn-outline-primary:not(:disabled):not(.disabled):hover, .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .ais-InstantSearch__root .btn-outline-primary.ais-Pagination-item--selected:not(:disabled):not(.disabled) {
    color: white; }

.btn-secondary {
  color: #fff; }
  .btn-secondary.btn-lg, .btn-group-lg > .btn-secondary.btn, .cookieConsent .btn-group-lg > button.btn-secondary {
    border-color: #ff9a5c;
    background-image: linear-gradient(to bottom, #ff9a5c 0%, #f47b30 100%);
    background-repeat: repeat-x; }
    .btn-secondary.btn-lg:active, .btn-group-lg > .btn-secondary.btn:active, .cookieConsent .btn-group-lg > button.btn-secondary:active, .btn-secondary.btn-lg:hover, .btn-group-lg > .btn-secondary.btn:hover, .cookieConsent .btn-group-lg > button.btn-secondary:hover {
      border-color: #f47b30; }

.dropdown-menu button {
  cursor: pointer; }

.form-control::-webkit-input-placeholder, .ais-InstantSearch__root .ais-SortBy-select::-webkit-input-placeholder, .AutoCompleteSearch .react-autosuggest__input::-webkit-input-placeholder, .Checkout .StripeElement::-webkit-input-placeholder {
  font-size: 0.9375rem; }

.form-control:-ms-input-placeholder, .ais-InstantSearch__root .ais-SortBy-select:-ms-input-placeholder, .AutoCompleteSearch .react-autosuggest__input:-ms-input-placeholder, .Checkout .StripeElement:-ms-input-placeholder {
  font-size: 0.9375rem; }

.form-control::-ms-input-placeholder, .ais-InstantSearch__root .ais-SortBy-select::-ms-input-placeholder, .AutoCompleteSearch .react-autosuggest__input::-ms-input-placeholder, .Checkout .StripeElement::-ms-input-placeholder {
  font-size: 0.9375rem; }

.form-control::placeholder, .ais-InstantSearch__root .ais-SortBy-select::placeholder, .AutoCompleteSearch .react-autosuggest__input::placeholder, .Checkout .StripeElement::placeholder {
  font-size: 0.9375rem; }

.form-control-lg, .Checkout .StripeElement {
  line-height: 1.5rem; }

.list-group-item.active a, .ais-InstantSearch__root .list-group-item.ais-Pagination-item--selected a {
  color: #fff; }

.modal-header + .modal-body {
  padding-top: 0; }

.navbar-light .nav-link {
  color: rgba(0, 0, 0, 0.5); }

.navbar-brand img {
  width: 65px; }

.popover {
  padding: 2rem;
  max-width: none; }
  .popover .form-control:focus, .popover .ais-InstantSearch__root .ais-SortBy-select:focus, .ais-InstantSearch__root .popover .ais-SortBy-select:focus, .popover .AutoCompleteSearch .react-autosuggest__input:focus, .AutoCompleteSearch .popover .react-autosuggest__input:focus, .popover .Checkout .StripeElement:focus, .Checkout .popover .StripeElement:focus {
    box-shadow: none; }

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  min-width: 320px; }

body {
  background-color: #f8f9fa; }

:focus {
  outline: #3dbfbe auto 5px; }

header,
section,
footer,
aside,
nav,
main,
article,
figure {
  display: block; }

th {
  font-weight: 500;
  font-size: 0.875rem; }

h1, .h1 {
  margin-bottom: 1.5rem;
  color: #f47b30;
  font-weight: 300; }

h2, .h2 {
  font-weight: 700;
  margin-bottom: 1rem; }

h2.light,
.h2-light {
  font-weight: 300;
  margin-bottom: 1rem; }

h3, .h3, .card-title {
  font-weight: 700; }

h4, .h4 {
  font-weight: 700; }

.btn-circle {
  text-align: center;
  padding: 0.5rem 0.9rem;
  border-radius: 100%; }

.mobile-menu-toggler {
  position: relative; }
  .mobile-menu-toggler__icon {
    width: 21px;
    height: 16px;
    display: inline-block;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer; }
    .mobile-menu-toggler__icon span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out; }
      .mobile-menu-toggler__icon span:nth-child(1) {
        top: 0; }
      .mobile-menu-toggler__icon span:nth-child(2), .mobile-menu-toggler__icon span:nth-child(3) {
        top: 7px; }
      .mobile-menu-toggler__icon span:nth-child(4) {
        top: 14px; }
  .mobile-menu-toggler--white .mobile-menu-toggler__icon span {
    background: white; }
  .mobile-menu-toggler--black .mobile-menu-toggler__icon span {
    background: #484848; }
  .mobile-menu-toggler--open {
    z-index: 1031;
    color: white; }
    .mobile-menu-toggler--open .mobile-menu-toggler__icon span {
      background: white; }
    .mobile-menu-toggler--open .mobile-menu-toggler__icon span:nth-child(2) {
      transform: rotate(45deg); }
    .mobile-menu-toggler--open .mobile-menu-toggler__icon span:nth-child(1) {
      top: 9px;
      width: 0;
      left: 50%; }
    .mobile-menu-toggler--open .mobile-menu-toggler__icon span:nth-child(3) {
      transform: rotate(-45deg); }
    .mobile-menu-toggler--open .mobile-menu-toggler__icon span:nth-child(4) {
      top: 9px;
      width: 0;
      left: 50%; }

.cursor-pointer {
  cursor: pointer !important; }

.cursor-default {
  cursor: default !important; }

.gradient {
  position: absolute; }

.gradient-top {
  left: 0;
  top: 0;
  width: 100%; }
  .gradient-top.gradient-primary {
    background-image: linear-gradient(to bottom, rgba(61, 191, 190, 0.65) 0%, rgba(61, 191, 190, 0) 100%);
    background-repeat: repeat-x; }
  .gradient-top.gradient-secondary {
    background-image: linear-gradient(to bottom, rgba(244, 123, 48, 0.65) 0%, rgba(244, 123, 48, 0) 100%);
    background-repeat: repeat-x; }
  .gradient-top.gradient-success {
    background-image: linear-gradient(to bottom, rgba(50, 183, 52, 0.65) 0%, rgba(50, 183, 52, 0) 100%);
    background-repeat: repeat-x; }
  .gradient-top.gradient-info {
    background-image: linear-gradient(to bottom, rgba(50, 143, 226, 0.65) 0%, rgba(50, 143, 226, 0) 100%);
    background-repeat: repeat-x; }
  .gradient-top.gradient-warning {
    background-image: linear-gradient(to bottom, rgba(255, 191, 0, 0.65) 0%, rgba(255, 191, 0, 0) 100%);
    background-repeat: repeat-x; }
  .gradient-top.gradient-danger {
    background-image: linear-gradient(to bottom, rgba(245, 65, 86, 0.65) 0%, rgba(245, 65, 86, 0) 100%);
    background-repeat: repeat-x; }
  .gradient-top.gradient-light {
    background-image: linear-gradient(to bottom, rgba(243, 243, 243, 0.65) 0%, rgba(243, 243, 243, 0) 100%);
    background-repeat: repeat-x; }
  .gradient-top.gradient-dark {
    background-image: linear-gradient(to bottom, rgba(94, 94, 94, 0.65) 0%, rgba(94, 94, 94, 0) 100%);
    background-repeat: repeat-x; }
  .gradient-top.gradient-white {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0) 100%);
    background-repeat: repeat-x; }
  .gradient-top.gradient-black {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
    background-repeat: repeat-x; }
  .gradient-top.gradient-purple {
    background-image: linear-gradient(to bottom, rgba(147, 61, 223, 0.65) 0%, rgba(147, 61, 223, 0) 100%);
    background-repeat: repeat-x; }

.gradient-bottom {
  left: 0;
  bottom: 0;
  width: 100%; }
  .gradient-bottom.gradient-primary {
    background-image: linear-gradient(to bottom, rgba(61, 191, 190, 0) 0%, rgba(61, 191, 190, 0.65) 100%);
    background-repeat: repeat-x; }
  .gradient-bottom.gradient-secondary {
    background-image: linear-gradient(to bottom, rgba(244, 123, 48, 0) 0%, rgba(244, 123, 48, 0.65) 100%);
    background-repeat: repeat-x; }
  .gradient-bottom.gradient-success {
    background-image: linear-gradient(to bottom, rgba(50, 183, 52, 0) 0%, rgba(50, 183, 52, 0.65) 100%);
    background-repeat: repeat-x; }
  .gradient-bottom.gradient-info {
    background-image: linear-gradient(to bottom, rgba(50, 143, 226, 0) 0%, rgba(50, 143, 226, 0.65) 100%);
    background-repeat: repeat-x; }
  .gradient-bottom.gradient-warning {
    background-image: linear-gradient(to bottom, rgba(255, 191, 0, 0) 0%, rgba(255, 191, 0, 0.65) 100%);
    background-repeat: repeat-x; }
  .gradient-bottom.gradient-danger {
    background-image: linear-gradient(to bottom, rgba(245, 65, 86, 0) 0%, rgba(245, 65, 86, 0.65) 100%);
    background-repeat: repeat-x; }
  .gradient-bottom.gradient-light {
    background-image: linear-gradient(to bottom, rgba(243, 243, 243, 0) 0%, rgba(243, 243, 243, 0.65) 100%);
    background-repeat: repeat-x; }
  .gradient-bottom.gradient-dark {
    background-image: linear-gradient(to bottom, rgba(94, 94, 94, 0) 0%, rgba(94, 94, 94, 0.65) 100%);
    background-repeat: repeat-x; }
  .gradient-bottom.gradient-white {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.65) 100%);
    background-repeat: repeat-x; }
  .gradient-bottom.gradient-black {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    background-repeat: repeat-x; }
  .gradient-bottom.gradient-purple {
    background-image: linear-gradient(to bottom, rgba(147, 61, 223, 0) 0%, rgba(147, 61, 223, 0.65) 100%);
    background-repeat: repeat-x; }

.gradient-left {
  top: 0;
  left: 0;
  height: 100%; }
  .gradient-left.gradient-primary {
    background-image: linear-gradient(to right, rgba(61, 191, 190, 0.65) 0%, rgba(61, 191, 190, 0) 100%);
    background-repeat: repeat-x; }
  .gradient-left.gradient-secondary {
    background-image: linear-gradient(to right, rgba(244, 123, 48, 0.65) 0%, rgba(244, 123, 48, 0) 100%);
    background-repeat: repeat-x; }
  .gradient-left.gradient-success {
    background-image: linear-gradient(to right, rgba(50, 183, 52, 0.65) 0%, rgba(50, 183, 52, 0) 100%);
    background-repeat: repeat-x; }
  .gradient-left.gradient-info {
    background-image: linear-gradient(to right, rgba(50, 143, 226, 0.65) 0%, rgba(50, 143, 226, 0) 100%);
    background-repeat: repeat-x; }
  .gradient-left.gradient-warning {
    background-image: linear-gradient(to right, rgba(255, 191, 0, 0.65) 0%, rgba(255, 191, 0, 0) 100%);
    background-repeat: repeat-x; }
  .gradient-left.gradient-danger {
    background-image: linear-gradient(to right, rgba(245, 65, 86, 0.65) 0%, rgba(245, 65, 86, 0) 100%);
    background-repeat: repeat-x; }
  .gradient-left.gradient-light {
    background-image: linear-gradient(to right, rgba(243, 243, 243, 0.65) 0%, rgba(243, 243, 243, 0) 100%);
    background-repeat: repeat-x; }
  .gradient-left.gradient-dark {
    background-image: linear-gradient(to right, rgba(94, 94, 94, 0.65) 0%, rgba(94, 94, 94, 0) 100%);
    background-repeat: repeat-x; }
  .gradient-left.gradient-white {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0) 100%);
    background-repeat: repeat-x; }
  .gradient-left.gradient-black {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
    background-repeat: repeat-x; }
  .gradient-left.gradient-purple {
    background-image: linear-gradient(to right, rgba(147, 61, 223, 0.65) 0%, rgba(147, 61, 223, 0) 100%);
    background-repeat: repeat-x; }

.gradient-right {
  top: 0;
  right: 0;
  height: 100%; }
  .gradient-right.gradient-primary {
    background-image: linear-gradient(to right, rgba(61, 191, 190, 0) 0%, rgba(61, 191, 190, 0.65) 100%);
    background-repeat: repeat-x; }
  .gradient-right.gradient-secondary {
    background-image: linear-gradient(to right, rgba(244, 123, 48, 0) 0%, rgba(244, 123, 48, 0.65) 100%);
    background-repeat: repeat-x; }
  .gradient-right.gradient-success {
    background-image: linear-gradient(to right, rgba(50, 183, 52, 0) 0%, rgba(50, 183, 52, 0.65) 100%);
    background-repeat: repeat-x; }
  .gradient-right.gradient-info {
    background-image: linear-gradient(to right, rgba(50, 143, 226, 0) 0%, rgba(50, 143, 226, 0.65) 100%);
    background-repeat: repeat-x; }
  .gradient-right.gradient-warning {
    background-image: linear-gradient(to right, rgba(255, 191, 0, 0) 0%, rgba(255, 191, 0, 0.65) 100%);
    background-repeat: repeat-x; }
  .gradient-right.gradient-danger {
    background-image: linear-gradient(to right, rgba(245, 65, 86, 0) 0%, rgba(245, 65, 86, 0.65) 100%);
    background-repeat: repeat-x; }
  .gradient-right.gradient-light {
    background-image: linear-gradient(to right, rgba(243, 243, 243, 0) 0%, rgba(243, 243, 243, 0.65) 100%);
    background-repeat: repeat-x; }
  .gradient-right.gradient-dark {
    background-image: linear-gradient(to right, rgba(94, 94, 94, 0) 0%, rgba(94, 94, 94, 0.65) 100%);
    background-repeat: repeat-x; }
  .gradient-right.gradient-white {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.65) 100%);
    background-repeat: repeat-x; }
  .gradient-right.gradient-black {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    background-repeat: repeat-x; }
  .gradient-right.gradient-purple {
    background-image: linear-gradient(to right, rgba(147, 61, 223, 0) 0%, rgba(147, 61, 223, 0.65) 100%);
    background-repeat: repeat-x; }

.icon-input-group {
  position: relative; }
  .icon-input-group input[type="text"],
  .icon-input-group input[type="email"],
  .icon-input-group input[type="password"] {
    padding-right: 2.6rem; }
  .icon-input-group svg {
    position: absolute;
    right: 1rem;
    top: 0.75rem; }
  .icon-input-group.icon-input-group-sm svg {
    top: 0.5rem; }
  .icon-input-group.icon-input-group-lg svg {
    top: 1rem; }

.search-icon, .AutoCompleteSearch .react-autosuggest__input, .ClubDir input[type="text"] {
  background-image: url(/static/media/search-solid.771ec063.svg);
  background-size: 1rem 1rem;
  background-repeat: no-repeat;
  background-position: 1rem 50%;
  padding-left: 2.8rem;
  transition: 0.2s all ease; }
  .search-icon:focus, .AutoCompleteSearch .react-autosuggest__input:focus, .ClubDir input:focus[type="text"], .search-icon:active, .AutoCompleteSearch .react-autosuggest__input:active, .ClubDir input:active[type="text"] {
    background-image: none; }
    .search-icon:focus.form-control-lg, .AutoCompleteSearch .react-autosuggest__input:focus.form-control-lg, .AutoCompleteSearch .Checkout .react-autosuggest__input.StripeElement:focus, .Checkout .AutoCompleteSearch .react-autosuggest__input.StripeElement:focus, .Checkout .search-icon.StripeElement:focus, .Checkout .ClubDir input.StripeElement:focus[type="text"], .ClubDir .Checkout input.StripeElement:focus[type="text"], .ClubDir input:focus.form-control-lg[type="text"], .search-icon:active.form-control-lg, .AutoCompleteSearch .react-autosuggest__input:active.form-control-lg, .AutoCompleteSearch .Checkout .react-autosuggest__input.StripeElement:active, .Checkout .AutoCompleteSearch .react-autosuggest__input.StripeElement:active, .Checkout .search-icon.StripeElement:active, .Checkout .ClubDir input.StripeElement:active[type="text"], .ClubDir .Checkout input.StripeElement:active[type="text"], .ClubDir input:active.form-control-lg[type="text"] {
      padding-left: 1rem; }
    .search-icon:focus.form-control-sm, .AutoCompleteSearch .react-autosuggest__input:focus.form-control-sm, .ClubDir input:focus.form-control-sm[type="text"], .search-icon:active.form-control-sm, .AutoCompleteSearch .react-autosuggest__input:active.form-control-sm, .ClubDir input:active.form-control-sm[type="text"] {
      padding-left: 0.5rem; }
    .search-icon:focus.form-control, .ais-InstantSearch__root .search-icon.ais-SortBy-select:focus, .ais-InstantSearch__root .ClubDir input.ais-SortBy-select:focus[type="text"], .ClubDir .ais-InstantSearch__root input.ais-SortBy-select:focus[type="text"], .AutoCompleteSearch .react-autosuggest__input:focus, .Checkout .search-icon.StripeElement:focus, .Checkout .ClubDir input.StripeElement:focus[type="text"], .ClubDir .Checkout input.StripeElement:focus[type="text"], .ClubDir input:focus.form-control[type="text"], .search-icon:active.form-control, .ais-InstantSearch__root .search-icon.ais-SortBy-select:active, .ais-InstantSearch__root .ClubDir input.ais-SortBy-select:active[type="text"], .ClubDir .ais-InstantSearch__root input.ais-SortBy-select:active[type="text"], .AutoCompleteSearch .react-autosuggest__input:active, .Checkout .search-icon.StripeElement:active, .Checkout .ClubDir input.StripeElement:active[type="text"], .ClubDir .Checkout input.StripeElement:active[type="text"], .ClubDir input:active.form-control[type="text"] {
      padding-left: 0.75rem; }

.CustomAlert {
  font-size: 0.9375rem;
  border-radius: 0 0.1875rem 0.1875rem 0;
  border: 1px solid #cccccc;
  padding: 1.3rem 1.3rem 1.3rem 0.65rem;
  background: white; }
  .CustomAlert.danger {
    border-left: 5px solid #f54156; }
    .CustomAlert.danger svg {
      color: #f54156; }
  .CustomAlert.warning {
    border-left: 5px solid #ffbf00; }
    .CustomAlert.warning svg {
      color: #ffbf00; }
  .CustomAlert.info {
    border-left: 5px solid #17a2b8; }
    .CustomAlert.info svg {
      color: #17a2b8; }
  .CustomAlert.success {
    border-left: 5px solid #32b734; }
    .CustomAlert.success svg {
      color: #32b734; }

.form-logo {
  max-width: 100px; }

.icon-container {
  width: 21px;
  height: 21px;
  display: inline-block; }
  .icon-container svg {
    vertical-align: top; }
  .icon-container .icon {
    fill: #f47b30; }
  .icon-container.buggy-missing .icon {
    fill: #b6b6b6; }
  .icon-container.buggy-on_site .icon {
    fill: #3dbfbe; }
  .icon-container.buggy-available .icon {
    fill: #f47b30; }
  .icon-container.buggy-included .icon {
    fill: #32b734; }

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  margin: -4.8px 0;
  border: none;
  padding: 0; }
  input[type=range]:focus {
    outline: none; }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    box-shadow: none;
    border-radius: 10px;
    border: none; }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    box-shadow: none;
    border-radius: 10px;
    border: none; }
  input[type=range]::-ms-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    box-shadow: none;
    border-radius: 10px;
    border: none; }
  input[type=range]::-ms-track {
    background: transparent;
    border-color: transparent;
    color: transparent; }
  input[type=range]::-moz-range-track {
    background: #e7e7e7; }
  input[type=range]::-webkit-slider-runnable-track {
    background: #e7e7e7; }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #e7e7e7; }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: none;
    border: none;
    height: 24px;
    width: 24px;
    border-radius: 12px;
    background: #3dbfbe;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -6px; }
  input[type=range]::-ms-thumb {
    box-shadow: none;
    border: none;
    height: 24px;
    width: 24px;
    border-radius: 12px;
    background: #3dbfbe;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -4px; }
  input[type=range]::-moz-range-thumb {
    box-shadow: none;
    border: none;
    height: 24px;
    width: 24px;
    border-radius: 12px;
    background: #3dbfbe;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -6px; }
  input[type=range]::-ms-fill-lower {
    background: #e7e7e7;
    border: none;
    border-radius: 0px;
    box-shadow: none; }
  input[type=range]::-ms-fill-upper {
    background: #e7e7e7;
    border: none;
    border-radius: 0px;
    box-shadow: none; }
  input[type=range]:focus::-ms-fill-lower {
    background: #e7e7e7;
    border: none;
    border-radius: 0px;
    box-shadow: none; }
  input[type=range]:focus::-ms-fill-upper {
    background: #e7e7e7;
    border: none;
    border-radius: 0px;
    box-shadow: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75, .SearchHit .card-img-top.no-image img {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

@media (min-width: 576px) {
  .w-sm-25 {
    width: 25% !important; }
  .w-sm-50 {
    width: 50% !important; }
  .w-sm-75 {
    width: 75% !important; }
  .w-sm-100 {
    width: 100% !important; }
  .h-sm-25 {
    height: 25% !important; }
  .h-sm-50 {
    height: 50% !important; }
  .h-sm-75 {
    height: 75% !important; }
  .h-sm-100 {
    height: 100% !important; } }

@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important; }
  .w-md-50 {
    width: 50% !important; }
  .w-md-75 {
    width: 75% !important; }
  .w-md-100 {
    width: 100% !important; }
  .h-md-25 {
    height: 25% !important; }
  .h-md-50 {
    height: 50% !important; }
  .h-md-75 {
    height: 75% !important; }
  .h-md-100 {
    height: 100% !important; } }

@media (min-width: 1025px) {
  .w-lg-25 {
    width: 25% !important; }
  .w-lg-50 {
    width: 50% !important; }
  .w-lg-75 {
    width: 75% !important; }
  .w-lg-100 {
    width: 100% !important; }
  .h-lg-25 {
    height: 25% !important; }
  .h-lg-50 {
    height: 50% !important; }
  .h-lg-75 {
    height: 75% !important; }
  .h-lg-100 {
    height: 100% !important; } }

@media (min-width: 1441px) {
  .w-xl-25 {
    width: 25% !important; }
  .w-xl-50 {
    width: 50% !important; }
  .w-xl-75 {
    width: 75% !important; }
  .w-xl-100 {
    width: 100% !important; }
  .h-xl-25 {
    height: 25% !important; }
  .h-xl-50 {
    height: 50% !important; }
  .h-xl-75 {
    height: 75% !important; }
  .h-xl-100 {
    height: 100% !important; } }

@media (min-width: 1921px) {
  .w-xxl-25 {
    width: 25% !important; }
  .w-xxl-50 {
    width: 50% !important; }
  .w-xxl-75 {
    width: 75% !important; }
  .w-xxl-100 {
    width: 100% !important; }
  .h-xxl-25 {
    height: 25% !important; }
  .h-xxl-50 {
    height: 50% !important; }
  .h-xxl-75 {
    height: 75% !important; }
  .h-xxl-100 {
    height: 100% !important; } }

.key-figure {
  font-size: 1.25rem;
  font-weight: 300; }
  .key-figure.key-figure-sm {
    font-size: 1.05rem; }
  .key-figure.key-figure-lg {
    font-size: 2rem; }

.teetime-summary {
  text-align: center; }
  .teetime-summary .teetime-summary-label {
    display: inline-block;
    margin-bottom: 0;
    margin-top: 3px;
    width: 35%;
    min-width: 35%; }

@media (max-width: 575.98px) {
  .restricted-width-xs.container,
  .restricted-width-xs .container {
    max-width: 300px; } }

@media (min-width: 576px) {
  .restricted-width-sm.container,
  .restricted-width-sm .container {
    max-width: 320px; } }

@media (min-width: 768px) {
  .restricted-width-md.container,
  .restricted-width-md .container {
    max-width: 480px; } }

@media (min-width: 1025px) {
  .restricted-width-lg.container,
  .restricted-width-lg .container {
    max-width: 760px; } }

@media (min-width: 1441px) {
  .restricted-width-xl.container,
  .restricted-width-xl .container {
    max-width: 1200px; } }

.auth-form {
  max-width: 370px;
  margin-left: auto;
  margin-right: auto; }
  .auth-form input[name="email"],
  .auth-form input[name="loginEmail"] {
    font-size: 1rem; }

.nowrap {
  white-space: nowrap; }

.upper {
  text-transform: uppercase; }

.lower {
  text-transform: lowercase; }

.line-through {
  text-decoration: line-through !important; }

.center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%); }

.center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.overflow-hidden {
  overflow: hidden; }

/* Cookie consent */
.cookieConsent {
  background: #484848;
  color: white;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 999999;
  bottom: 0; }

/* Algolia Instant Search */
.ais-InstantSearch__root .ais-CurrentRefinements {
  font-size: 0.875rem;
  color: white;
  display: inline-block; }
  .ais-InstantSearch__root .ais-CurrentRefinements--noRefinement {
    display: none; }
  .ais-InstantSearch__root .ais-CurrentRefinements-list {
    padding: 0;
    margin: 0; }
  .ais-InstantSearch__root .ais-CurrentRefinements-item {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    border-radius: 0.375rem;
    background-color: #3dbfbe; }
  .ais-InstantSearch__root .ais-CurrentRefinements-delete {
    line-height: 1.5;
    font-size: inherit;
    color: white; }
    .ais-InstantSearch__root .ais-CurrentRefinements-delete:hover {
      color: #f8f9fa; }

.ais-InstantSearch__root .ais-Hits-list {
  padding: 0; }

.ais-InstantSearch__root .ais-Hits-item {
  list-style-type: none; }
  .ais-InstantSearch__root .ais-Hits-item:empty {
    display: none; }

/* Google Maps*/
.gm-ui-hover-effect {
  top: -2px !important;
  right: 0 !important; }
  .gm-ui-hover-effect img {
    width: 18px !important;
    height: 18px !important; }

/* Slick Slider */
.slick-dots {
  position: static !important; }
  .slick-dots li {
    margin: 0 !important; }
    .slick-dots li button:before {
      color: #dadada !important;
      opacity: 1 !important;
      font-size: 10px !important; }
    .slick-dots li.slick-active button:before {
      color: #933ddf !important; }

.AutoCompleteSearch {
  text-align: left;
  position: relative; }
  .AutoCompleteSearch .react-autosuggest__container {
    position: relative; }
  .AutoCompleteSearch .react-autosuggest__input::-webkit-input-placeholder {
    font-style: italic; }
  .AutoCompleteSearch .react-autosuggest__input:-ms-input-placeholder {
    font-style: italic; }
  .AutoCompleteSearch .react-autosuggest__input::-ms-input-placeholder {
    font-style: italic; }
  .AutoCompleteSearch .react-autosuggest__input::placeholder {
    font-style: italic; }
  .AutoCompleteSearch .react-autosuggest__input::-ms-clear {
    display: none; }
  .AutoCompleteSearch .react-autosuggest__input--focused {
    background-image: none;
    padding-left: 1rem; }
    .AutoCompleteSearch .react-autosuggest__input--focused + div {
      border: 1px solid #f3f3f3; }
  .AutoCompleteSearch .react-autosuggest__clear-btn {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0.2rem; }
  .AutoCompleteSearch .react-autosuggest__suggestions-container {
    min-width: 100%;
    background-color: #fff;
    position: absolute;
    z-index: 1;
    border-radius: 5px; }
    .AutoCompleteSearch .react-autosuggest__suggestions-container:empty {
      display: none; }
  .AutoCompleteSearch .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none; }
  .AutoCompleteSearch .react-autosuggest__suggestion {
    font-size: 0.8125rem;
    cursor: pointer; }
    .AutoCompleteSearch .react-autosuggest__suggestion p {
      font-size: 0.8rem; }
    .AutoCompleteSearch .react-autosuggest__suggestion a:hover {
      text-decoration: none;
      color: #3dbfbe; }
    .AutoCompleteSearch .react-autosuggest__suggestion .svg-inline--fa {
      color: #f47b30;
      font-size: 1rem;
      height: 1rem; }
    .AutoCompleteSearch .react-autosuggest__suggestion .isvg {
      display: inline-block;
      width: 16px;
      height: 20px; }
      .AutoCompleteSearch .react-autosuggest__suggestion .isvg .icon {
        fill: #f47b30; }
    .AutoCompleteSearch .react-autosuggest__suggestion__icon-container {
      display: inline-block;
      text-align: center;
      flex: 0 0 1.75rem;
      margin-top: 4px; }
    .AutoCompleteSearch .react-autosuggest__suggestion--highlighted {
      background-color: #f3f3f3; }
  .AutoCompleteSearch .react-autosuggest__section-title {
    font-size: 0.75rem;
    text-transform: uppercase;
    color: #a0a0a0;
    border-bottom: 1px solid #f3f3f3; }

.Body {
  flex: 1 0 auto;
  min-height: 300px;
  position: relative; }

.Checkout .StripeElement {
  padding: .85rem; }

@supports (-ms-ime-align: auto) {
  .CheckoutCartClub .card-body {
    flex-direction: column; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .CheckoutCartClub .card-body {
    flex-direction: column; } }

.CheckoutCartClubRow {
  position: relative;
  background-color: rgba(61, 191, 190, 0.04); }
  .CheckoutCartClubRow .alert {
    margin: -1rem -1rem 1rem -1rem; }

.Club {
  background-color: white; }
  .Club h2 {
    font-weight: 300; }
  @media (min-width: 1025px) {
    .Club.active aside, .ais-InstantSearch__root .Club.ais-Pagination-item--selected aside {
      position: relative;
      top: -145px; } }

.ClubAmenityList li {
  list-style: none; }

.ClubAmenityList .amenity-icon {
  min-width: 45px;
  max-width: 45px;
  font-size: 20px; }
  .ClubAmenityList .amenity-icon .isvg {
    display: inline-block;
    height: 22px;
    width: 100%; }

.ClubAmenityList .amenity-name {
  font-size: 0.9375rem; }

.ClubAmenityList .amenity-exists .amenity-icon {
  color: #3dbfbe; }
  .ClubAmenityList .amenity-exists .amenity-icon .icon {
    fill: #3dbfbe; }

.ClubAmenityList .amenity-missing .amenity-icon {
  color: #cccccc; }
  .ClubAmenityList .amenity-missing .amenity-icon .icon {
    fill: #cccccc; }

.ClubAmenityList .amenity-missing .amenity-name {
  color: #cccccc;
  text-decoration: line-through; }

.ClubCover {
  z-index: 0;
  overflow: hidden; }
  .ClubCover figcaption {
    position: absolute;
    font-weight: 300;
    line-height: 2rem;
    color: white;
    left: 0;
    vertical-align: bottom; }
    @media (max-width: 575.98px) {
      .ClubCover figcaption {
        font-size: 1.3rem;
        left: 0; } }
    @media (min-width: 576px) {
      .ClubCover figcaption {
        font-size: 1.5rem; } }
    @media (min-width: 768px) {
      .ClubCover figcaption {
        font-size: 1.7rem; } }
  .ClubCover figcaption, .ClubCover__rating {
    bottom: 6%;
    z-index: 1;
    padding: 0.75rem 1.5rem; }
    @media (max-width: 575.98px) {
      .ClubCover figcaption, .ClubCover__rating {
        bottom: 0; } }
  .ClubCover__rating {
    position: absolute;
    right: 0;
    z-index: 1;
    font-size: 1.7rem;
    font-weight: 300;
    color: white; }
    .ClubCover__rating .svg-inline--fa {
      color: #ffbf00;
      font-size: 0.9rem;
      position: relative;
      top: -2px; }
  .ClubCover button {
    position: absolute;
    left: 1rem;
    bottom: 1rem; }
    @media (max-width: 1024.98px) {
      .ClubCover button {
        right: 1rem;
        top: 1rem;
        left: auto;
        bottom: auto; } }
  .ClubCover__shadow {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50%;
    width: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9)); }
    @media (min-width: 1025px) {
      .ClubCover__shadow {
        height: 33%; } }

.ClubRatesTable img {
  width: 25px;
  height: 25px; }

.ClubRatesTable.weekend-rates td,
.ClubRatesTable.weekend-rates th {
  font-size: 85%; }

.ClubRatesTable .weekend {
  background: rgba(244, 123, 48, 0.05); }

.ClubRatesTable thead tr.description-row th {
  font-size: 80%; }

.ClubRatesTable tr th:not(:first-child),
.ClubRatesTable tr td:not(:first-child) {
  text-align: center; }

.ClubRatesTable tbody tr td:first-child {
  white-space: nowrap; }

.ClubRatesTable .seasons {
  transition: all 0.5s ease; }

.ClubReviewList__star {
  color: #ffbf00; }

.ClubReviewList__rating {
  font-size: 2.1875rem; }

.ClubReviewList h4 {
  font-size: 0.6875rem; }

.ClubReviewList ul li {
  font-size: 0.875rem; }
  .ClubReviewList ul li strong {
    font-size: 0.8125rem; }
  .ClubReviewList ul li .svg-inline--fa {
    color: #ff9a5c;
    font-size: 0.625rem; }

.ClubTeeTimeCard {
  background-color: #f8f9fa;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.15);
  top: 10px;
  position: -webkit-sticky;
  position: sticky; }
  .ClubTeeTimeCard.card {
    border: none; }
  .ClubTeeTimeCard .card-header {
    background-color: #5e5e5e;
    border-color: #5e5e5e;
    color: white;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
    .ClubTeeTimeCard .card-header h2 {
      font-size: 1.4rem; }

.ClubTeeTimeItem {
  cursor: pointer; }
  .ClubTeeTimeItem:focus, .ClubTeeTimeItem:active {
    outline: none; }
  .ClubTeeTimeItem .dot {
    height: 9px;
    width: 9px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 2px; }
    .ClubTeeTimeItem .dot.dot-active {
      background-color: #3dbfbe; }
    .ClubTeeTimeItem .dot.dot-inactive {
      background-color: #a0a0a0; }
  .ClubTeeTimeItem .players {
    min-width: 45px; }
  .ClubTeeTimeItem:not(.active) .players,
  .ClubTeeTimeItem:not(.active) .from {
    color: #b6b6b6; }
  .ClubTeeTimeItem.disabled {
    color: #a0a0a0;
    background-color: #cccccc; }
    .ClubTeeTimeItem.disabled .dot-active {
      background-color: #a0a0a0; }
  .ClubTeeTimeItem.active, .ais-InstantSearch__root .ClubTeeTimeItem.ais-Pagination-item--selected {
    border-color: #000;
    background-color: #2f2f2f; }
    .ClubTeeTimeItem.active svg .buggy path, .ais-InstantSearch__root .ClubTeeTimeItem.ais-Pagination-item--selected svg .buggy path {
      fill: white; }
    .ClubTeeTimeItem.active .teetime-info-text, .ais-InstantSearch__root .ClubTeeTimeItem.ais-Pagination-item--selected .teetime-info-text {
      color: #fff; }
  .ClubTeeTimeItem .svg-inline--fa {
    position: relative;
    top: 3px; }

.ClubTeeTimeList {
  flex-direction: row;
  flex-wrap: wrap;
  overflow: scroll;
  overflow-x: auto;
  max-height: 335px; }
  @media (max-width: 575.98px) {
    .ClubTeeTimeList {
      max-height: 260px; } }

.ClubDir input[type="text"] {
  max-width: 500px; }

.ClubDir .table thead th {
  border-top: none; }

@media (min-width: 768px) {
  .ClubDir tbody tr td:nth-child(4) {
    white-space: nowrap; } }

.ClubDir .table-striped tbody tr:nth-of-type(odd) {
  background-color: white; }

.ClubDir .table-striped tbody tr:nth-of-type(odd):hover, .ClubDir .table-striped tbody tr:hover {
  background-color: #3dbfbe; }
  .ClubDir .table-striped tbody tr:nth-of-type(odd):hover td, .ClubDir .table-striped tbody tr:hover td {
    cursor: pointer;
    color: white; }
    .ClubDir .table-striped tbody tr:nth-of-type(odd):hover td.active, .ClubDir .table-striped tbody tr:nth-of-type(odd):hover .ais-InstantSearch__root td.ais-Pagination-item--selected, .ais-InstantSearch__root .ClubDir .table-striped tbody tr:nth-of-type(odd):hover td.ais-Pagination-item--selected, .ClubDir .table-striped tbody tr:nth-of-type(odd):hover td.inactive, .ClubDir .table-striped tbody tr:hover td.active, .ClubDir .table-striped tbody tr:hover .ais-InstantSearch__root td.ais-Pagination-item--selected, .ais-InstantSearch__root .ClubDir .table-striped tbody tr:hover td.ais-Pagination-item--selected, .ClubDir .table-striped tbody tr:hover td.inactive {
      color: white; }

.ClubDir .table-striped tbody tr td.active, .ClubDir .table-striped tbody tr .ais-InstantSearch__root td.ais-Pagination-item--selected, .ais-InstantSearch__root .ClubDir .table-striped tbody tr td.ais-Pagination-item--selected {
  color: #32b734; }

.ClubDir .table-striped tbody tr td.inactive {
  color: #b6b6b6; }

.ContentBlock h2 {
  text-align: center;
  font-size: 1.625rem;
  font-weight: 900; }

.ContentBlock__description {
  text-align: center;
  color: #8a8a8a;
  font-size: 1.25rem; }

.ContentBlock__separator {
  height: 4px;
  width: 90px;
  background-image: linear-gradient(to right, #3dbfbe 0%, #f47b30 100%);
  background-repeat: repeat-x; }

.ContentBlock__separator--alt {
  background-image: linear-gradient(to right, #3dbfbe 0%, #933ddf 100%);
  background-repeat: repeat-x; }

.CookiePolicy tbody tr td:nth-child(3) span {
  display: block;
  font-size: 0.875rem;
  color: #747474;
  padding-left: 10px;
  text-indent: -10px; }

@media (max-width: 1024.98px) {
  .CookiePolicy table thead {
    display: none; }
  .CookiePolicy table tr {
    display: block;
    margin-bottom: 3rem; }
  .CookiePolicy table td {
    display: block;
    padding-left: 0;
    padding-right: 0; }
    .CookiePolicy table td:first-child {
      font-weight: bold;
      font-size: 1.125rem;
      border-top: none; }
    .CookiePolicy table td::before {
      content: attr(data-label);
      display: block;
      margin-bottom: 0.25rem;
      color: #b6b6b6; } }

.Footer {
  flex-shrink: 0;
  position: relative;
  background-color: #f3f3f3; }
  .Footer img {
    width: 84px;
    height: auto; }
  .Footer button.btn-secondary {
    background-color: #ff9a5c;
    border-color: #ff9a5c; }
    .Footer button.btn-secondary:active {
      background-color: #f47b30 !important;
      border-color: #f47b30 !important; }
  .Footer li {
    list-style-type: none; }
  .Footer .row > div, .Footer .ais-InstantSearch__root .ais-Hits-list > div, .ais-InstantSearch__root .Footer .ais-Hits-list > div {
    word-break: break-word; }
  @media (max-width: 767.98px) {
    .Footer .NewsletterSignup {
      margin-left: -4px; } }
  .Footer__credit-cards li {
    display: inline-block;
    font-size: 1.9rem;
    color: #b6b6b6; }

body {
  background-color: white; }

.GolfArea header {
  transform: translateY(-50%); }
  .GolfArea header > div {
    border-radius: 3rem; }
  .GolfArea header + div {
    font-size: 1.125rem;
    line-height: 140%; }
    @media (min-width: 768px) {
      .GolfArea header + div {
        column-count: 2;
        grid-column-gap: 2rem;
        column-gap: 2rem; } }

@media (max-width: 575.98px) {
  .GolfArea .GolfArea__cover {
    height: 200px; } }

@media (min-width: 576px) {
  .GolfArea .GolfArea__cover {
    height: 320px; } }

@media (min-width: 768px) {
  .GolfArea .GolfArea__cover {
    height: 400px; } }

@media (min-width: 1025px) {
  .GolfArea .GolfArea__cover {
    height: 500px; } }

@media (min-width: 1441px) {
  .GolfArea .GolfArea__cover {
    height: 600px; } }

.GolfArea__course .card-image-top img,
.GolfArea__course .card-image-top a {
  height: 150px; }

.GolfArea__figure {
  background-color: #61d9d8; }

.GolfArea__category-text p:last-child {
  margin-bottom: 0; }

.GolfCard h3 {
  font-size: 1.375rem; }

.GolfCard__img {
  position: relative;
  background-size: cover;
  background-position: center center; }

.GolfCard__gradient {
  position: absolute;
  width: 100%;
  height: 34px;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)); }

.GolfCard__rating {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.65rem 1rem;
  z-index: 2; }
  .GolfCard__rating span {
    font-size: 1.125rem; }
  .GolfCard__rating .svg-inline--fa {
    color: #ffbf00;
    font-size: 0.75rem; }

.GolfCardCircle__image--outline {
  border-radius: 100%;
  box-shadow: 0 0 0 3px rgba(61, 191, 190, 0.5);
  padding: 5px;
  transition: box-shadow 0.2s, padding 0.2s; }
  .GolfCardCircle__image--outline:hover {
    box-shadow: 0 0 0 6px rgba(61, 191, 190, 0.2);
    padding: 8px;
    transition: box-shadow 0.2s, padding 0.2s; }

.GolfCardCircle__rating figure {
  font-size: 0.875rem; }

.GolfCardCircle__rating span {
  font-size: 0.9375rem;
  position: relative;
  top: 1px; }

.Header {
  position: relative; }
  .Header .navbar {
    min-height: 60px;
    background-color: white;
    border-bottom: 1px solid #cccccc; }
    .Header .navbar .btn-link:focus {
      box-shadow: 0 0 0 0.3rem rgba(61, 191, 190, 0.5); }
  .Header .nav-item {
    white-space: nowrap; }
  @media (max-width: 575.98px) {
    .Header__search {
      flex: 1 1; }
      .Header__search--focus {
        position: absolute;
        width: calc(100% - 2rem);
        z-index: 1; } }

.HeroImage {
  height: 280px; }
  @media (max-width: 575.98px) {
    .HeroImage {
      height: 250px; } }
  .HeroImage .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: black;
    background-size: cover;
    background-position: center; }
  .HeroImage .text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: 300px; }
  .HeroImage h2 {
    font-weight: 700;
    font-size: 2.25rem; }
    @media (max-width: 575.98px) {
      .HeroImage h2 {
        font-size: 1.875rem; } }
  .HeroImage h3 {
    font-weight: 300;
    font-size: 1.3125rem; }
    @media (max-width: 575.98px) {
      .HeroImage h3 {
        font-size: 1.25rem;
        text-shadow: 0px 1px 16px rgba(0, 0, 0, 0.5); } }

.Layout {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column; }

.Loader {
  width: 100%; }
  .Loader.loader-fullscreen {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8); }
  .Loader .loader-inner {
    text-align: center;
    margin: 0 auto;
    width: 100%; }

.MobileMenu {
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  overflow-x: hidden;
  overflow-y: auto; }
  .MobileMenu ul {
    list-style-type: none;
    padding-left: 0; }
  .MobileMenu .nav-link {
    color: white; }
  .MobileMenu .nav > li {
    opacity: 0;
    transform: translateY(-40px);
    animation-timing-function: cubic-bezier(0.45, 0.005, 0, 1); }
    .MobileMenu .nav > li > a,
    .MobileMenu .nav > li > button {
      font-size: 1.1875rem;
      font-weight: 700;
      margin: 0 auto; }
  .MobileMenu .fa-angle-down {
    transform: rotate(0deg);
    transition: transform 0.2s; }
  .MobileMenu .flip {
    transition: 0.2s;
    transform: rotate(180deg); }
  .MobileMenu--open {
    opacity: 1;
    visibility: visible;
    background-image: linear-gradient(to bottom, #61d9d8 0%, #3dbfbe 100%);
    background-repeat: repeat-x; }
    .MobileMenu--open .nav > li {
      opacity: 1;
      transform: translateY(0px);
      transition: transform 0.2s, opacity 0.2s; }
  .MobileMenu__lang-menu {
    max-width: 350px;
    opacity: 0;
    visibility: hidden;
    text-align: left;
    height: 0; }
    .MobileMenu__lang-menu hr {
      width: 20%;
      opacity: 0;
      border-top-color: white; }
    .MobileMenu__lang-menu button {
      flex: 50% 1;
      max-width: 50%;
      opacity: 0;
      transform: translateY(-20px);
      animation-timing-function: cubic-bezier(0.45, 0.005, 0, 1);
      overflow: hidden;
      text-align: left; }
    .MobileMenu__lang-menu--open {
      opacity: 1;
      visibility: visible;
      height: auto; }
      .MobileMenu__lang-menu--open hr {
        width: 75%;
        opacity: 0.3;
        transition: opacity 0.2s, width 0.2s; }
      .MobileMenu__lang-menu--open > button {
        opacity: 1;
        transform: translateY(0px);
        transition: transform 0.2s, opacity 0.2s; }

.Order .card-title {
  font-size: 1.1rem; }

.OrderBooking .card-title {
  font-weight: 300; }

.PageMenu ul {
  list-style-type: none;
  padding-left: 0; }
  @media (max-width: 767.98px) {
    .PageMenu ul {
      border-right: 0 !important; } }

.PageMenu li {
  border-left: 3px solid transparent; }
  .PageMenu li.active, .PageMenu .ais-InstantSearch__root li.ais-Pagination-item--selected, .ais-InstantSearch__root .PageMenu li.ais-Pagination-item--selected {
    border-left: 3px solid #3dbfbe;
    font-weight: bold; }
    .PageMenu li.active a, .PageMenu .ais-InstantSearch__root li.ais-Pagination-item--selected a, .ais-InstantSearch__root .PageMenu li.ais-Pagination-item--selected a {
      font-weight: bold; }

.NewsletterSignup form {
  max-width: 400px; }
  @media (max-width: 575.98px) {
    .NewsletterSignup form {
      margin: 0 auto; } }

.NewsletterSignup p {
  color: #b6b6b6; }

.Search .ais-Hits-item {
  list-style-type: none; }

.SearchFacets {
  border-bottom: 1px solid #cccccc;
  background-color: white; }

.SearchHit__gradient {
  position: absolute;
  width: 100%;
  height: 34px;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)); }

.SearchHit__rating {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.65rem 1rem;
  z-index: 2; }
  .SearchHit__rating span {
    font-size: 1.125rem; }
  .SearchHit__rating .svg-inline--fa {
    color: #ffbf00;
    font-size: 0.75rem; }

.SearchHit .card-img-top {
  overflow: hidden;
  position: relative; }
  .SearchHit .card-img-top.no-image {
    text-align: center;
    background-color: #f3f3f3; }
    .SearchHit .card-img-top.no-image img {
      opacity: 0.1; }

.SearchShortcutButton {
  border-radius: 2rem;
  font-size: 0.9375rem;
  padding: 0.45rem 1rem; }
  .SearchShortcutButton--shadow {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 6px 0px; }
  .SearchShortcutButton:hover.btn-primary {
    box-shadow: 0 0 0 0.3rem rgba(61, 191, 190, 0.5); }
  .SearchShortcutButton:hover.btn-secondary {
    box-shadow: 0 0 0 0.3rem rgba(244, 123, 48, 0.5); }

.Start h1 {
  font-weight: 900;
  font-size: 2.0625rem;
  max-width: 450px; }
  @media (max-width: 767.98px) {
    .Start h1 {
      font-size: 1.625rem; } }

.Start .navbar .btn-link:focus {
  box-shadow: 0 0 0 0.3rem rgba(61, 191, 190, 0.5); }

.Start .navbar .nav-link {
  color: white; }

.Start .navbar .navbar-text {
  font-size: 0.9375rem; }

.Start header {
  position: relative; }

.Start__header-overlay {
  background-image: linear-gradient(to bottom, #1cd8d7 0%, #059b9a 100%);
  background-repeat: repeat-x;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.9; }

.Start__header-image {
  background-position: center;
  background-size: cover;
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%); }

.Start .AutoCompleteSearch .react-autosuggest__input {
  border-radius: 2rem;
  padding: 1.35rem 1.35rem 1.35rem 2.75rem; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .Start .AutoCompleteSearch .react-autosuggest__input {
      min-height: 3rem;
      padding: 0.6rem 2.75rem 0.6rem; } }

.Start .AutoCompleteSearch .react-autosuggest__input--focused {
  padding-left: 1.25rem;
  padding-right: 2.75rem; }

.Start .AutoCompleteSearch .react-autosuggest__clear-btn {
  right: 0.4rem; }

.Start .AutoCompleteSearch .react-autosuggest__suggestions-container {
  top: 3.1rem;
  border-radius: 1.5rem;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2); }

.StartGuideStep {
  text-align: center;
  display: inline-block;
  position: relative; }
  .StartGuideStep figure {
    width: 74px;
    height: 74px;
    border-radius: 100%;
    position: relative;
    z-index: 1;
    margin: 0 auto 1rem auto; }
    .StartGuideStep figure .svg-inline--fa {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      font-size: 1.5625rem;
      color: white; }
  .StartGuideStep strong {
    display: block; }
  .StartGuideStep p {
    font-size: 0.875rem;
    line-height: 1.2; }

.StartTestimonials p {
  font-size: 1.5rem;
  quotes: "\201C" "\201D" "\2018" "\2019";
  padding: 10px 20px;
  line-height: 1.4; }
  .StartTestimonials p:before, .StartTestimonials p::after {
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    display: inline;
    line-height: 0;
    color: #e9e9e9;
    font-size: 3em;
    position: relative; }
  .StartTestimonials p:before {
    content: open-quote;
    left: -12px;
    top: 24px; }
  .StartTestimonials p::after {
    content: close-quote;
    left: 7px;
    top: 38px; }

.StartTestimonials footer {
  font-style: italic;
  float: right;
  font-size: 1.175rem;
  color: #ccc; }

.TeeTimeIconsInfo .icon-container {
  width: 26px;
  height: 26px; }

.TeeTimeIconsInfo p,
.TeeTimeIconsInfo strong {
  display: block;
  font-size: 0.875rem;
  margin-bottom: 0; }

.USPList li {
  list-style-type: none;
  font-size: 0.875rem; }

